/*eslint-disable*/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import '../style/sidebar.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faFileContract, faTv } from "@fortawesome/free-solid-svg-icons";
import logo from '../media/logo-green.png';

import { useMsal } from "@azure/msal-react";
import { REPORT_CLIENT, REPORT_SOCIO, CONTRACT } from "../sidemenu";

export default function Sidebar(props) {
    const [collapseShow, setCollapseShow] = React.useState("hidden");
    const { instance } = useMsal();

    useEffect(() => {
      console.log(props);
    });
  
    const handleLogout = () => {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
    };

  return (
    <>
      <nav className="sidebar menu md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          {/* <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button> */}
          {/* Brand */}
          {/* <Link
            className=""
            to="/"
          >
                <img className="logo" src={logo} alt="seustudio" />
            </Link> */}
          {/* User */}
          {/* <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
            </li>
            <li className="inline-block relative">
            </li>
          </ul> */}
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            {/* <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Seu Studio
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div> */}
            {/* Form */}
            {/* <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form> */}

            {/* Divider */}
            {/* <hr className="md:min-w-full" /> */}
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 no-underline">
              Relatórios
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "item-text text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  // to="/portal"
                  onClick={() => props.onOptionSelect(REPORT_CLIENT)}
                >
                  <FontAwesomeIcon className="item-logo" icon={faTv} />
                  Clientes
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "item-text text-xs uppercase font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  // to="/portal"
                  onClick={() => props.onOptionSelect(REPORT_SOCIO)}
                >
                  <FontAwesomeIcon className="item-logo" icon={faTv} />
                  Sócios
                </Link>
              </li>
            </ul>
            {/* Divider */}
            {/* <hr className="md:min-w-full" /> */}
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 no-underline">
              Contratos
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "item-text text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  // to="/portal"
                  onClick={() => props.onOptionSelect(CONTRACT)}
                >
                  <FontAwesomeIcon className="item-logo" icon={faFileContract} />
                  Exportar Contrato
                </Link>
              </li>
            </ul>


            <ul style={{position:'absolute', bottom:'0'}}
              className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className="item-text text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon className="item-logo" icon={faArrowRightFromBracket} />
                  Logout
                </Link>
              </li>
            </ul>
          </div>
          
        </div>
      </nav>
    </>
  );
}
