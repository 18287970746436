/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Form, InputGroup,
} from 'react-bootstrap';

function ReportAdminFee(props) {
  const handleChangeAdministrationFee = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    props.administrationFee(event.target.value);
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Form.Group controlId="formApartmentInfo">
        <Form.Label>Taxa de administração</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            placeholder="Taxa de administração"
            required
            defaultValue={props.default}
            onChange={handleChangeAdministrationFee}
            min={0}
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Form>
  );
}

export default ReportAdminFee;
