/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Card, Col, Row, Accordion, Alert,
} from 'react-bootstrap';
// import { CHECK_IN_YEAR_MONTH_UPLOAD } from '../utils/helper';
import { useMsal } from '@azure/msal-react';
import ReportDataExport from '../components/ReportDataExport';
import ReportDataUpload from '../components/ReportDataUpload';
import ReportExtras from '../components/ReportExtras';
import ReportExtraCostsModal from '../components/ReportExtraCostsModal';
import ReportReservationModal from '../components/ReportReservationModal';
import ReportSetDate from '../components/ReportSetDate';
import ReportTable from '../components/ReportTable';
import ReportExtraCostsTable from '../components/ReportExtraCostsTable';
import { initData, isDataInvalid, resolveData } from '../utils/airbnbDataHelper';
import {
  getUniqueDates, getUniqueListings, getFilteredRowsByDate, getFilteredRowsByListing, calculateAdminFee, calculateCleaningFee,
} from '../utils/dataHelper';
import ReportAdminFee from '../components/ReportAdminFee';
import clients from '../clients';
import MailTo from '../components/Mailto';

import '../style/report.css';
import { REPORT_CLIENT } from '../sidemenu';
import ReportSetListing from '../components/ReportSetListing';
import ReportCleaningFee from '../components/ReportCleaningFee';

function Report() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [data, setData] = useState([]);
  const [selectedYearMonth, setSelectedYearMonth] = useState('');
  const [selectedListing, setSelectedListing] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [fileId, setFileId] = useState('');
  const [administrationFee, setAdministrationFee] = useState(15);
  const [cleaningFee, setCleaningFee] = useState(120);
  const [extraFees, setExtraFees] = useState([]);
  const [client, setClient] = useState([]);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [uniqueListings, setUniqueListings] = useState([]);
  const [dataIsInvalid, setDataIsInvalid] = useState(false);

  useEffect(() => {
    setSelectedYearMonth('');
  }, [fileId]);

  useEffect(() => {
    const fd = calculateAdminFee(filteredData, administrationFee);
    const d = calculateAdminFee(data, administrationFee);
    setFilteredData(fd);
    setData(d);
  }, [administrationFee]);

  useEffect(() => {
    const fd = calculateCleaningFee(filteredData, cleaningFee);
    const d = calculateCleaningFee(data, cleaningFee);
    setFilteredData(fd);
    setData(d);
  }, [cleaningFee]);

  const handleFileData = (uploadedData, meta) => {
    const d = initData(uploadedData, administrationFee, cleaningFee);

    setDataIsInvalid(isDataInvalid(d));
    setData(d);
    setFilteredData(d);
    setFileId(meta.id);
  };

  const validateData = () => {
    const fixedData = resolveData(data);
    setDataIsInvalid(false);
    setData(resolveData(data));
    setFilteredData(resolveData(filteredData));
  };

  const getClients = (input) => {
    if (!input || input.length === 0) return;

    const dateSet = new Set();
    // Add all 'unit' fields to the set
    input.map((i) => i.unit.value).map((u) => dateSet.add(u));
    const filteredClients = clients.filter((c) => Array.from(dateSet).some((value) => c.unit.includes(value)));
    // const filteredClients = clients.filter((c) => Array.from(dateSet).includes(c.unit));

    return filteredClients;
    // if (filteredClients.length === 0 || filteredClients.length > 1) {
    //   console.error('Unexpected client');
    //   return undefined;
    // }

    // return filteredClients[0];
  };

  useEffect(() => {
    setClient(getClients(data));
    setUniqueDates(getUniqueDates(data));
    setUniqueListings(getUniqueListings(data));
  }, [data]);

  const handleSelectedYearMonth = (selectedDate) => {
    if (!selectedDate) {
      setFilteredData([...data]);
      setSelectedYearMonth(selectedDate);
      return;
    }

    let dataCopy = getFilteredRowsByDate({ ...data }, selectedDate);
    if (selectedListing) {
      dataCopy = getFilteredRowsByListing({ ...dataCopy }, selectedListing);
    }

    setFilteredData(dataCopy);
    setSelectedYearMonth(selectedDate);
  };

  const handleSelectedListing = (selListing) => {
    if (!selListing) {
      setFilteredData([...data]);
      setSelectedListing(selListing);
      return;
    }

    let dataCopy = getFilteredRowsByListing({ ...data }, selListing);
    if (selectedYearMonth) {
      dataCopy = getFilteredRowsByDate({ ...dataCopy }, selectedYearMonth);
    }
    setFilteredData(dataCopy);
    setSelectedListing(selListing);
  };

  const handleAdministrationFee = (adminFee) => {
    setAdministrationFee(adminFee);
  };

  const handleCleaningFee = (cleanFee) => {
    setCleaningFee(Number(cleanFee));
  };

  const handleAddedExtraCost = (cost) => {
    setExtraFees([...extraFees, cost]);
  };

  const handleDeleteExtraCost = (index) => {
    const newCosts = JSON.parse(JSON.stringify(extraFees));
    newCosts.splice(index, 1);
    setExtraFees(newCosts);
  };

  // What should be done when a new reservation is entered?
  // - Reset date filter
  // - Add to the raw data
  const handleExtraReservationAdded = (obj) => {
    const copyData = JSON.parse(JSON.stringify(data)); // Deep copy
    copyData.push(obj);
    setData(copyData);
    setFilteredData(copyData);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="top-bar" />
          <h3 className="portal-title">Relatório para Cliente</h3>
          {/* <h5 style={{ marginTop: '1em' }}>Configurações</h5> */}
          {dataIsInvalid ? (
            <Alert key="danger" variant="danger">
              O arquivo contém pelo menos uma taxa de limpeza igual a zero.
              {' '}
              <Alert.Link onClick={validateData}>Clique aqui</Alert.Link>
              {' '}
              para arrumar
            </Alert>
          ) : null}
          <div className="input-session" style={{ marginTop: '1em' }}>
            <div>
              <Row>
                <Col className="configuration" md={4} xs={12}>
                  {/* <Card.Subtitle className="mb-2 text-muted">Importar Arquivo do Airbnb</Card.Subtitle> */}
                  <ReportDataUpload fileData={handleFileData} type={REPORT_CLIENT} />
                </Col>
                <Col className="configuration" md={4} xs={12}>
                  <ReportAdminFee administrationFee={handleAdministrationFee} default={15} />
                </Col>
                <Col className="configuration" md={4} xs={12}>
                  <ReportCleaningFee cleaningFee={handleCleaningFee} default={120} />
                </Col>

              </Row>

              <Row style={{ marginTop: '1em' }}>
                <Col className="configuration" md={6} xs={12}>
                  <ReportSetListing
                    listings={uniqueListings}
                    fileIdUploaded={fileId}
                    onListingSelected={handleSelectedListing}
                  />
                </Col>
                <Col className="configuration" md={3} xs={12}>
                  <ReportSetDate
                    dates={uniqueDates}
                    fileIdUploaded={fileId}
                    onYearMonthSelected={handleSelectedYearMonth}
                  />
                </Col>
                <Col className="configuration" md={3} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <ReportReservationModal
                    filterClient={client}
                    extraReservationAdded={handleExtraReservationAdded}
                    adminFee={administrationFee}
                  />
                </Col>

              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 style={{ marginTop: '1em' }}>Reservas</h5>
          <div className="input-session" style={{ marginTop: '1em' }}>
            <div>
              {(filteredData && filteredData.length > 0)
                ? <ReportTable data={filteredData} />
                : <p style={{ margin: '0', textAlign: 'center' }}>Nenhum arquivo foi selecionado</p>}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 style={{ marginTop: '1em' }}>Cobranças extras</h5>
          <div className="input-session" style={{ marginTop: '1em' }}>
            <div>
              {(extraFees && extraFees.length > 0)
                ? <ReportExtraCostsTable data={extraFees} extraFeesDeleted={handleDeleteExtraCost} />
                : <p style={{ margin: '0', textAlign: 'center' }}>Nenhuma cobranças extra foi criada</p>}
            </div>
            <div style={{ padding: '1rem' }}>
              <ReportExtraCostsModal extraCostAdded={handleAddedExtraCost} />
            </div>
          </div>
        </Col>
      </Row>
      <ReportDataExport
        className="action-button"
        data={filteredData}
        yearMonth={selectedYearMonth}
        administrationFee={administrationFee}
        extraFees={extraFees}
        enabled={selectedListing && selectedListing.length > 0 && selectedYearMonth && selectedYearMonth.length > 0}
      />
    </>
  );
}

export default Report;
