/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form, InputGroup, Row, Col, Button, Table, Card, Modal,
} from 'react-bootstrap';
import { numberToBRSCurrency } from '../utils/dataHelper';

import '../style/extraCostsModal.css';

function ReportBillsModal(props) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');

  const valueChange = (v) => {
    setValue(numberToBRSCurrency(v));
  };

  const handleClose = () => {
    setName('');
    setValue('');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleAddField = () => {
    props.extraCostAdded({ name, value });
    setName('');
    setValue('');
    handleClose();
  };

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    >
      <Button variant="outline-success" onClick={handleShow} style={{ marginTop: 'auto', width: '25%' }}>
        Adicionar custo
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Adicionar custo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group style={{ marginTop: '1rem' }}>
              <Form.Label>Valor</Form.Label>
              <Form.Control
                type="number"
                placeholder="R$ 0,00"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                required
                prefix="R$"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAddField} disabled={name === '' || value === ''}>
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    // <Row>
    //   <Col md={4} xs={12}>
    //     <Card.Subtitle className="mb-2 text-muted">Adiciona detailes</Card.Subtitle>
  // <Form>
  //   <Form.Group>
  //     <Form.Label>Descrição</Form.Label>
  //     <Form.Control
  //       type="text"
  //       value={name}
  //       onChange={(e) => setName(e.target.value)}
  //       required
  //     />
  //   </Form.Group>
  //   <Form.Group>
  //     <Form.Label>Valor</Form.Label>
  //     <Form.Control
  //       type="number"
  //       value={value}
  //       onChange={(e) => setValue(e.target.value)}
  //       required
  //     />
  //   </Form.Group>
  //   <Button variant="success" style={{ marginTop: '1em', marginBottom: '1em' }} onClick={handleAddField} disabled={name === '' || value === ''}>Adicionar</Button>
  // </Form>
    //   </Col>
    //   <Col md={8} xs={12}>
    //     <Card.Subtitle className="mb-2 text-muted">Dados do Extra fees</Card.Subtitle>
    //     <Table striped bordered hover>
    //       <thead>
    //         <tr>
    //           <th>Descrição</th>
    //           <th>Valor</th>
    //           <th>Acao</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {fields.length !== 0
    //           ? fields.map((field, index) => (
    //             <tr key={index}>
    //               <td>
    //                 {fields[index].name}
    //               </td>
    //               <td>
    //                 {numberToBRSCurrency(fields[index].value / 100)}
    //               </td>
    //               <td>
    //                 <Button
    //                   variant="success"
    //                   onClick={() => handleDeleteField(index)}
    //                 >
    //                   Apagar
    //                 </Button>
    //               </td>
    //             </tr>
    //           )) : null}
    //       </tbody>
    //     </Table>
    //   </Col>
    // </Row>

  );
}

export default ReportBillsModal;
