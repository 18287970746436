import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from '../media/logo-white.png';
import qrCode from '../media/qr-code.svg';

const headerStyle = {
  backgroundColor: '#124d54', // Example gradient, adjust as needed
  color: '#fff',
  textAlign: 'center',
  padding: '10px',
};

const logoStyle = {
  width: '100px', // Adjust as needed
  height: '30px', // Adjust as needed
  marginRight: '10px', // Adjust as needed for spacing
  margin: 'auto',
  marginTop: '1rem',
  marginBottom: '1rem',
};

function ShoppingList() {
  return (
    <div style={{ backgroundColor: '#f7f8f9' }}>

      <div style={headerStyle}>
        <h1 style={{ margin: '0', marginTop: '3rem' }}>Lista de Compras</h1>
        <img src={logo} style={{ ...logoStyle, marginBottom: '3rem' }} alt="Seu Studio logo" />
      </div>

      <div className="container">
        <div className="row my-4">
          <h2 className="title my-4">Cozinha</h2>
        </div>
        {/* Cozinha */}
        <div className="row my-4">
          <div className="col-md-6">
            {/* <h2 className="title">Cozinha</h2> */}
            <ul>
              <li className="description">1 geladeira (a partir de 260 litros)</li>
              <li className="description">1 forno microondas (a partir de 25L)</li>
              <li className="description">1 forno elétrico P ou M</li>
              <li className="description">1 fogão elétrico (a partir de 2 bocas, EVITAR indução)</li>
              <li className="description">1 filtro de água natural acoplado na torneira ou não</li>
              <li className="description">1 liquidificador</li>
              <li className="description">1 sanduicheira</li>
              <li className="description">1 cafeteira elétrica (com ou sem cápsula)</li>
              <li className="description">2 kits de potes de plástico para alimentos (pequeno, médio e grande)</li>
              <li className="description">1 jogo de panelas com 5 ou 7 peças (incluindo frigideira)</li>
              <li className="description">1 jogo de talher, garfo e faca (com 15 ou 20 peças)</li>
              <li className="description">1 jogo de facas (pão, carne e legumes)</li>
              <li className="description">1 tesoura doméstica</li>
              <li className="description">1 suporte para coador de papel (café médio)</li>
              <li className="description">1 garrafa de café média</li>
              <li className="description">1 pote para açúcar</li>
            </ul>
          </div>
          <div className="col-md-6">
            {/* <img src={image} alt="Kitchen Essentials" className="img-fluid" /> */}
            <ul>
              <li className="description">1 pote para sal</li>
              <li className="description">1 pote para café em pó</li>
              <li className="description">1 tábua de cortes (vidro ou plástico)</li>
              <li className="description">1 jarra grande para sucos</li>
              <li className="description">1 jogo de 6 copos de vidro (médio ou grande)</li>
              <li className="description">1 jogo de 4 a 6 taças (para vinho)</li>
              <li className="description">4 a 6 pratos rasos e fundos, pires e xícaras (uma opção é comprar o kit)</li>
              <li className="description">1 abridor de garrafas e latas simples</li>
              <li className="description">1 ralador</li>
              <li className="description">1 protetor de ralo para pia</li>
              <li className="description">1 dispenser de detergente</li>
              <li className="description">1 escorredor de louças (plástico ou metal)</li>
            </ul>
          </div>
        </div>

        {/* Banheiro */}
        <div className="row my-4">
          <h2 className="title my-4">Banheiro</h2>
        </div>
        <div className="row my-4">
          <div className="col-md-6">
            {/* <h2 className="title">Cozinha</h2> */}
            <ul>
              <li className="description">1 espelho de parede (a partir de 60cm)</li>
              <li className="description">1 dispenser de sabão líquido</li>
              <li className="description">1 porta escovas</li>
              <li className="description">1 secador de cabelos</li>
              <li className="description">1 saboneteira</li>
              <li className="description">1 lixeira a partir de 7 litros</li>
              <li className="description">1 escova sanitária</li>
              <li className="description">3 jogos de toalhas (rosto, banho e piso)</li>
            </ul>
          </div>
          <div className="col-md-6">
            {/* <img src={image} alt="Kitchen Essentials" className="img-fluid" /> */}
          </div>
        </div>

        {/* Dormitório/Sala */}
        <div className="row my-4">
          <h2 className="title my-4">Dormitório/Sala</h2>
        </div>
        <div className="row my-4">
          <div className="col-md-6">
            <ul>
              <li className="description">1 cama casal padrão / queen / king</li>
              <li className="description">1 colchão casal padrão / queen / king</li>
              <li className="description">1 abajour para leitura</li>
              <li className="description">1 mesinha lateral de apoio a cama</li>
              <li className="description">1 espelho de corpo inteiro</li>
              <li className="description">1 roupeiro com maleiro</li>
              <li className="description">10-15 cabides</li>
              <li className="description">1 ferro de passar ou steamer</li>
              <li className="description">1 tábua de passar</li>
              <li className="description">1 smartTV a partir de 32 polegadas</li>
              <li className="description">1 sofá cama (solteiro ou casal)</li>
              <li className="description">1 jogo de almofadas</li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul>
              <li className="description">1 ventilador (ou ar condicionado)</li>
              <li className="description">1 aquecedor ou ar condicionado</li>
              <li className="description">1 tapete de pano pequeno ou médio (lavável em máquina de lavar)</li>
              <li className="description">1 protetor de colchão</li>
              <li className="description">3 Lençóis de algodão</li>
              <li className="description">3 mantas</li>
              <li className="description">4 travesseiros (sendo dois baixos e dois altos)</li>
              <li className="description">2 travesseiros extras (sofá cama)</li>
              <li className="description">6 fronhas neutras</li>
              <li className="description">2 cobreleitos de algodão (preferencialmente)</li>
              <li className="description">1 manta de algodão para sofá</li>
            </ul>
          </div>
        </div>

        {/* Manutenção */}
        <div className="row my-4">
          <h2 className="title my-4">Manutenção</h2>
        </div>
        <div className="row my-4">
          <div className="col-md-6">
            <ul>
              <li className="description">1 aspirador de pó vertical</li>
              <li className="description">1 vassoura</li>
              <li className="description">1 rodo</li>
              <li className="description">1 rodo de pia</li>
              <li className="description">4 panos de chão</li>
              <li className="description">2 flanelas de móveis</li>
              <li className="description">4 panos de prato</li>
              <li className="description">1 balde médio</li>

            </ul>
          </div>
          <div className="col-md-6" />
        </div>
      </div>

      <div
        className="row mt-4"
        style={{
          backgroundColor: '#124d54',
          color: '#fff',
          textAlign: 'center',
          paddingBottom: '5rem',
          paddingTop: '5rem',
          marginLeft: '0',
          marginRight: '0',
          paddingLeft: '10%',
          paddingRight: '10%',
        }}
      >
        <div className="col-md-6" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          <p className="title">Montamos seu studio pra você!</p>
          <p className="title">Entre em contato conosco</p>
          <p className="description" style={{ color: 'white', marginBottom: '0' }}>www.seustudio.com.br</p>
          <p className="description" style={{ color: 'white', marginBottom: '2rem' }}>+55 (11) 97533-7766</p>
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <div
            className="w-100 h-100 d-flex justify-content-center align-items-center"
            style={{ maxHeight: '10rem', maxWidth: '10rem', margin: 'auto' }}
          >
            <img src={qrCode} alt="whats app contact qr code" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingList;
