/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */
/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */

import {
  buildData, numberToCurrency,
} from './dataHelper';

const DATE_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;

const expectedCSVColumnsClient = [
  'Código de confirmação',
  'Status',
  'Nome do hóspede',
  'Entrar em contato',
  'Nº de adultos',
  'Nº de crianças',
  'Nº de bebês',
  'Data de início',
  'Data de término',
  'Nº de noites',
  'Reservado',
  'Anúncio',
  'Ganhos'];

const fields = {
  checkIn: 5,
  numberOfNights: 6,
  estadia: 14,
  cleaningFee: 17,
  checkInDate: 19,
  checkInYearMonth: 20,
  adminFee: 22,
};

const addCurrency = [14, 16, 17, 22];

const skipColumnsTable = [20, 19, 18, 15, 13, 12, 11, 10, 9, 8, 3, 2, 1, 0];

// const outputTable = [
//   { name: 'Plataforma', currency: false },
//   { name: 'Código de Confirmação', currency: false },
//   { name: 'Check-In', currency: false },
//   { name: 'Check-Out', currency: false },
//   { name: '# de noites', currency: false },
//   { name: 'Hóspede', currency: false },
//   { name: 'Valor da estadia', currency: true },
//   { name: 'Taxa de Limpeza', currency: true },
//   { name: 'Taxa de Administração', currency: true },
//   { name: 'Unidade', currency: true },
// ];

// const getHeaders = () => outputTable.map((i) => i.name);

const stringToFloat = (amount) => {
  const number = Number(amount.replace(/[^0-9-]+/g, '')) / 100;
  return number;
};

const stringToDate = (dateStr) => {
  if (!dateStr) { return; }
  // if (!DATE_REGEX.test(dateStr)) { return; } // Check format MM/dd/YYYY

  let dateParts = '';
  let month = '';
  let day = '';
  let year = '';

  dateParts = dateStr.split('/');
  day = dateParts[0].padStart(2, '0');
  month = dateParts[1].padStart(2, '0');
  year = dateParts[2];

  return new Date(year, month - 1, day);
};

const stringToDateSumDays = (dateStr, plusDays) => {
  const date = stringToDate(dateStr);
  const day = date.getDate().toString().padStart(2, '0'); // get day and add leading zero if needed
  const month = (date.getMonth()).toString().padStart(2, '0'); // get month and add leading zero if needed
  const year = date.getFullYear().toString(); // get year
  const d = parseInt(day) + plusDays;

  return new Date(year, month, d.toString().padStart(2, '0'));
};

const initData = (data, administrationFee, cleanFee) => {
  let temp = [...data];
  const result = [];
  // Remove header
  temp = temp.slice(1, data.length);

  // Filter only "Reserva"
  // temp = temp.filter((item) => item && item.length !== 13);

  Array.from(temp, (arr) => {
    if (arr.length === expectedCSVColumnsClient.length) {
      const reservation = arr[0];
      const checkInDate = stringToDate(arr[7]);
      const checkOutDate = stringToDate(arr[8]);
      const nights = arr[9];
      const guest = arr[2];
      const listing = arr[11];
      const unit = arr[11];
      const value = stringToFloat(arr[12]) - cleanFee; // amount - cleaning fee
      const cleaningFee = cleanFee;

      let obj = buildData(
        guest,
        checkInDate,
        checkOutDate,
        nights,
        value,
        cleaningFee,
        unit,
        administrationFee,
        reservation,
        listing,
      );
      obj = {
        ...obj,
        source: {
          name: 'airbnb',
          currency: false,
          value: 'AirBnb',
          stringValue: 'AirBnb',
          show: true,
          tableOrder: 0,
        },
      };

      result.push(obj);
    }
  });
  return result;
};

const isDataInvalid = (data) => data.some((item) => item.cleaningFee.value === 0);

const resolveData = (data) => {
  const allCleaningFees = data.map((i) => i.cleaningFee.value);
  const uniqueCleaningFees = [...new Set(allCleaningFees)];
  const maxCleaningFee = Math.max(...uniqueCleaningFees);

  return data.map((i) => (i.cleaningFee.value === 0
    ? {
      ...i,
      cleaningFee: {
        ...i.cleaningFee,
        value: maxCleaningFee,
        stringValue: numberToCurrency(parseFloat(maxCleaningFee).toFixed(2)),
      },
    }
    : { ...i }));
};

const initDataSocio = (data, administrationFee) => {
  let temp = [...data];
  const result = [];

  // Remove header
  temp = temp.slice(1, data.length);

  // Filter only "Reserva"
  temp = temp.filter((item) => item && item[1] === 'Reserva');

  Array.from(temp, (arr) => {
    const reservation = arr[2];
    const checkInDate = stringToDate(arr[3]);
    const checkOutDate = stringToDateSumDays(arr[3], arr[4]);
    const nights = arr[4];
    const guest = arr[5];
    const listing = arr[6];
    const unit = arr[7];
    const value = arr[10] - arr[13]; // amount - cleaning fee
    const cleaningFee = arr[13];

    let obj = buildData(
      guest,
      checkInDate,
      checkOutDate,
      nights,
      value,
      cleaningFee,
      unit,
      administrationFee,
      reservation,
      listing,
    );
    obj = {
      ...obj,
      source: {
        name: 'airbnb',
        currency: false,
        value: 'AirBnb',
        stringValue: 'AirBnb',
        show: true,
        tableOrder: 0,
      },
    };

    result.push(obj);
  });
  return result;
};

export {
  initData, initDataSocio, isDataInvalid, resolveData,
};

// TODO:
// const extraReservationData = (guest, checkInDate, checkOutDate, numberOfNights, value, cleaningFee, administrationFee) => {
//   const obj = {
//     source: {
//       name: 'manual',
//       currency: false,
//       value: 'Manual',
//       stringValue: 'Manual',
//       show: true,
//       tableOrder: 0,
//     },
//     confirmation: {
//       name: 'Código de Confirmação',
//       currency: false,
//       value: 'Not available', // TODO:
//       stringValue: 'Not available', // TODO:
//       show: true,
//       tableOrder: 1,
//     },
//     listing: {
//       name: 'Listing',
//       currency: false,
//       value: 'Not applicable', // TODO:
//       stringValue: 'Not applicable', // TODO:
//       show: false,
//       tableOrder: null,
//     },
//     unit: {
//       name: 'Apelido da unidade',
//       currency: false,
//       value: 'arr[9]',
//       stringValue: 'arr[9]',
//       show: false,
//       tableOrder: null,
//     },
//     checkin: {
//       name: 'Check-In',
//       currency: false,
//       value: checkInDate,
//       stringValue: checkInDate,
//       show: true,
//       date: stringToDate(checkInDate),
//       tableOrder: 2,
//     },
//     night: {
//       name: '# de noites',
//       currency: false,
//       value: numberOfNights,
//       stringValue: numberOfNights.toString(),
//       show: true,
//       tableOrder: 4,
//     },
//     guest: {
//       name: 'Hóspede',
//       currency: false,
//       value: guest,
//       stringValue: guest,
//       show: true,
//       tableOrder: 5,
//     },
//     amount: {
//       name: 'Valor da estadia',
//       currency: true,
//       value,
//       stringValue: numberToCurrency(value),
//       show: true,
//       tableOrder: 6,
//     },
//     cleaningFee: {
//       name: 'Taxa de Limpeza',
//       currency: true,
//       value,
//       stringValue: numberToCurrency(parseFloat(cleaningFee).toFixed(2)),
//       show: true,
//       tableOrder: 7,
//     },
//     checkout: {
//       name: 'Check-Out',
//       currency: false,
//       value: checkOutDate,
//       stringValue: checkOutDate,
//       show: true,
//       date: checkOutDate,
//       tableOrder: 3,
//     },
//     adminFee: {
//       name: 'Taxa de Administração',
//       currency: true,
//       value: parseFloat(((Number(administrationFee) * value) / 100).toFixed(2)),
//       stringValue: numberToCurrency(parseFloat(((Number(administrationFee) * value) / 100).toFixed(2))),
//       show: true,
//       tableOrder: 8,
//     },
//   };
//   return obj;
// };

// const getUniqueDates = (data) => {
//   if (!data) { return; }

//   const dateSet = new Set();
//   // Add all 'yearMonth' fields to the set
//   data.map((i) => i.yearMonth.value).map((ym) => dateSet.add(ym));

//   // convert to array and sort descending
//   const dateArray = Array.from(dateSet).sort().reverse();
//   return dateArray;
// };

// const getFilteredTableColumns = (data, stringOnly) => {
//   if (!data || data.length === 0) return;

//   const result = [];
//   Object.values(data).forEach((obj) => {
//     const res = [];
//     Object.entries(obj).forEach((entry) => {
//       if (entry[1].show) {
//         let value;
//         if (stringOnly) {
//           value = entry[1].currency ? (`R$ ${entry[1].stringValue}`) : entry[1].stringValue;
//           // res.push(entry[1].currency ? (`R$ ${entry[1].stringValue}`) : entry[1].stringValue);
//         } else {
//           value = entry[1].value;
//           // res.push(entry[1].value);
//         }
//         res[entry[1].tableOrder] = value;
//       }
//     });

//     result.push(res);
//   });
//   return result;
// };

// const getFilteredRowsByDate = (data, dateStr) => {
//   let dataCopy = { ...data };
//   dataCopy = Object.values(dataCopy).filter((row) => row.yearMonth.value === dateStr);
//   return dataCopy;
// };

// const numberToCurrency = (value) => Intl.NumberFormat(
//   'pt-BR',
//   {
//     maximumFractionDigits: 2,
//     minimumFractionDigits: 2,
//     // style: 'currency',
//     currency: 'BRL',
//   },
// ).format(value);

// const numberToBRSCurrency = (value) => `R$ ${numberToCurrency(value)}`;

// const convertAllToString = (data) => getFilteredTableColumns(data, true);

// const calculateSubtotalValue = (data) => {
//   if (!data || data.length === 0) return 0;

//   const adminFee = Object.values(data).map((i) => i.adminFee.value);
//   const adminFeeSum = adminFee.reduce((a, b) => a + b);
//   const cleaningFee = Object.values(data).map((i) => i.cleaningFee.value);
//   const cleaningFeeSum = cleaningFee.reduce((a, b) => a + b);

//   let total = adminFeeSum + cleaningFeeSum;
//   total = Math.round(total * 100) / 100;
//   return total;
// };

// const calculateSubtotal = (data, currency) => {
//   const total = calculateSubtotalValue(data, currency);
//   return currency ? numberToBRSCurrency(total) : numberToCurrency(total);
// };

// const calculateAdminFee = (data, percentFee) => {
//   if (!percentFee) {
//     percentFee = 0;
//   }
//   if (!data) {
//     return undefined;
//   }
//   const copy = [];
//   data.forEach((row) => {
//     const test = {
//       ...row,
//       adminFee: {
//         ...row.adminFee,
//         value: parseFloat(((Number(percentFee) * row.amount.value) / 100).toFixed(2)),
//         stringValue: numberToCurrency(parseFloat(((Number(percentFee) * row.amount.value) / 100).toFixed(2))),
//       },
//     };
//     copy.push(test);
//   });
//   return copy;
// };

// const getListing = (data) => {
//   if (!data || data.length === 0) return;

//   const dateSet = new Set();
//   // Add all 'yearMonth' fields to the set
//   data.map((i) => i.listing.value).map((ym) => dateSet.add(ym));

//   // convert to array and sort descending
//   const dateArray = Array.from(dateSet);
//   return dateArray;
// };

// const getUnit = (data) => {
//   if (!data || data.length === 0) return;

//   const dateSet = new Set();
//   data.map((i) => i.unit.value).map((ym) => dateSet.add(ym));

//   // convert to array and sort descending
//   const dateArray = Array.from(dateSet);
//   return dateArray;
// };
