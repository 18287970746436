/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-fragments */
import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import {
  RouterProvider, createBrowserRouter, Route, createRoutesFromElements, BrowserRouter, Routes,
} from 'react-router-dom';

import Root from './components/Root';
import Landing from './pages/Landing';
import ShoppingList from './pages/ShoppingList';
import Portal from './pages/Portal';
import components from './utils/components';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    // <RouterProvider router={router} />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/lista-de-compras" element={<ShoppingList />} />
        <Route
          path="/portal"
          element={(
          <React.Fragment>
            <AuthenticatedTemplate>
              <Portal />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <p>No users are signed in!</p>
            </UnauthenticatedTemplate>
          </React.Fragment>
)}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
