/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';

import logo from '../media/logo-white.png';
import '../style/footer.css';

export default function Footer() {
  return (
    <footer className="relative darkBlue pt-8 pb-6">
      <div className="container">
        <hr className="my-6 border-blueGray-300" />
        <div className="div-flex ">
          <img className="logo" src={logo} alt="seustudio" />
          <p className="text-sm text-blueGray-500 font-semibold py-1 copyright">
            Todos os direitos reservados à Seu Studio
          </p>
        </div>
      </div>
    </footer>
  );
}
