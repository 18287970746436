/* eslint-disable max-len */
import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Listing from './Listing';
import listings from '../listings';

import '../style/listingsModal.css';

function ListingsModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        onClick={handleShow}
        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
      >
        Ver todos nossos anúncios
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Nossos anúncios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="div-flex">
            {listings.map((listing) => (
              <div className="md4/12 sm12/12 padx">
                <Listing
                  data={listing}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ListingsModal;
