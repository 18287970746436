/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Card, Col, Row, Accordion, Alert,
} from 'react-bootstrap';
// import { CHECK_IN_YEAR_MONTH_UPLOAD } from '../utils/helper';
import { useMsal } from '@azure/msal-react';
import ReportDataExportSocios from '../components/ReportDataExportSocios';
import ReportDataUpload from '../components/ReportDataUpload';
import ReportExtras from '../components/ReportExtras';
import ReportBillsModal from '../components/ReportBillsModal';
import ReportReservationModal from '../components/ReportReservationModal';
import ReportSetDate from '../components/ReportSetDate';
import ReportTable from '../components/ReportTable';
import ReportExtraCostsTable from '../components/ReportExtraCostsTable';
import { initDataSocio, isDataInvalid, resolveData } from '../utils/airbnbDataHelper';
import { getUniqueDates, getFilteredRowsByDate, calculateAdminFee } from '../utils/dataHelper';
import ReportAdminFee from '../components/ReportAdminFee';
import clients from '../clients';
import MailTo from '../components/Mailto';

import '../style/report.css';
import { REPORT_SOCIO } from '../sidemenu';

function ReportSocios() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [data, setData] = useState([]);
  const [selectedYearMonth, setSelectedYearMonth] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [fileId, setFileId] = useState('');
  const [administrationFee, setAdministrationFee] = useState(15);
  const [bills, setBills] = useState([]);
  const [client, setClient] = useState([]);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [fixedCosts, setFixedCosts] = useState([]);
  const [dataIsInvalid, setDataIsInvalid] = useState(false);

  useEffect(() => {
    setSelectedYearMonth('');
  }, [fileId]);

  useEffect(() => {
    const fd = calculateAdminFee(filteredData, administrationFee);
    const d = calculateAdminFee(data, administrationFee);
    setFilteredData(fd);
    setData(d);
  }, [administrationFee]);

  const handleFileData = (uploadedData, meta) => {
    const d = initDataSocio(uploadedData, administrationFee);

    setDataIsInvalid(isDataInvalid(d));
    setData(d);
    setFilteredData(d);
    setFileId(meta.id);
  };

  const validateData = () => {
    const fixedData = resolveData(data);
    setDataIsInvalid(false);
    setData(resolveData(data));
    setFilteredData(resolveData(filteredData));
  };

  const getClients = (input) => {
    if (!input || input.length === 0) return;

    const dateSet = new Set();
    // Add all 'unit' fields to the set
    input.map((i) => i.unit.value).map((u) => dateSet.add(u));
    const filteredClients = clients.filter((c) => Array.from(dateSet).some((value) => c.unit.includes(value)));
    // const filteredClients = clients.filter((c) => Array.from(dateSet).includes(c.unit));

    return filteredClients;
    // if (filteredClients.length === 0 || filteredClients.length > 1) {
    //   console.error('Unexpected client');
    //   return undefined;
    // }

    // return filteredClients[0];
  };

  useEffect(() => {
    setClient(getClients(data));
    setUniqueDates(getUniqueDates(data));
  }, [data]);

  useEffect(() => {

  }, [filteredData]);

  const handleSelectedYearMonth = (selectedDate) => {
    if (!selectedDate) {
      setFilteredData([...data]);
      setSelectedYearMonth(selectedDate);
      return;
    }

    const dataCopy = getFilteredRowsByDate({ ...data }, selectedDate);
    setFilteredData(dataCopy);
    setSelectedYearMonth(selectedDate);
  };

  const handleAdministrationFee = (adminFee) => {
    setAdministrationFee(adminFee);
  };

  const handleAddedExtraCost = (cost) => {
    setBills([...bills, cost]);
  };

  const handleDeleteExtraCost = (index) => {
    const newCosts = JSON.parse(JSON.stringify(bills));
    newCosts.splice(index, 1);
    setBills(newCosts);
  };

  // What should be done when a new reservation is entered?
  // - Reset date filter
  // - Add to the raw data
  const handleExtraReservationAdded = (obj) => {
    const copyData = JSON.parse(JSON.stringify(data)); // Deep copy
    copyData.push(obj);
    setData(copyData);
    setFilteredData(copyData);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="top-bar" />
          <h3 className="portal-title">Relatório para Sócios</h3>
          {dataIsInvalid ? (
            <Alert key="danger" variant="danger">
              O arquivo contém pelo menos uma taxa de limpeza igual a zero.
              {' '}
              <Alert.Link onClick={validateData}>Clique aqui</Alert.Link>
              {' '}
              para arrumar
            </Alert>
          ) : null}
          {/* <h5 style={{ marginTop: '1em' }}>Configurações</h5> */}
          <div className="input-session" style={{ marginTop: '1em' }}>
            <div>
              <Row>
                <Col className="configuration" md={3} xs={12}>
                  {/* <Card.Subtitle className="mb-2 text-muted">Importar Arquivo do Airbnb</Card.Subtitle> */}
                  <ReportDataUpload fileData={handleFileData} type={REPORT_SOCIO} />
                </Col>
                <Col className="configuration" md={3} xs={12}>
                  <ReportSetDate dates={uniqueDates} fileIdUploaded={fileId} onYearMonthSelected={handleSelectedYearMonth} />
                </Col>
                <Col className="configuration" md={3} xs={12}>
                  <ReportAdminFee administrationFee={handleAdministrationFee} default={10} />
                </Col>
                <Col className="configuration" md={3} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <ReportReservationModal
                    filterClient={client}
                    extraReservationAdded={handleExtraReservationAdded}
                    adminFee={administrationFee}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 style={{ marginTop: '1em' }}>Reservas</h5>
          <div className="input-session" style={{ marginTop: '1em' }}>
            <div>
              {(filteredData && filteredData.length > 0)
                ? <ReportTable data={filteredData} />
                : <p style={{ margin: '0', textAlign: 'center' }}>Nenhum arquivo foi selecionado</p>}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 style={{ marginTop: '1em' }}>Custos</h5>
          <div className="input-session" style={{ marginTop: '1em' }}>
            <div>
              {(bills && bills.length > 0)
                ? <ReportExtraCostsTable data={bills} extraFeesDeleted={handleDeleteExtraCost} />
                : <p style={{ margin: '0', textAlign: 'center' }}>Nenhum custo foi criado</p>}
            </div>
            <div style={{ padding: '1rem' }}>
              <ReportBillsModal extraCostAdded={handleAddedExtraCost} />
            </div>
          </div>
        </Col>
      </Row>

      <ReportDataExportSocios
        className="action-button"
        data={filteredData}
        yearMonth={selectedYearMonth}
        administrationFee={administrationFee}
        bills={bills}
        enabled={selectedYearMonth && selectedYearMonth.length > 0}
      />
    </>
  );
}

export default ReportSocios;
