/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Form, InputGroup,
} from 'react-bootstrap';

function ReportCleaningFee(props) {
  const handleChangeCleaningFee = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    props.cleaningFee(event.target.value);
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Form.Group controlId="formApartmentInfo">
        <Form.Label>Taxa de limpeza</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            placeholder="Taxa de limpeza"
            required
            defaultValue={props.default}
            onChange={handleChangeCleaningFee}
            min={0}
          />
          <InputGroup.Text>R$</InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Form>
  );
}

export default ReportCleaningFee;
