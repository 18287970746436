/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { CHECK_IN_YEAR_MONTH_UPLOAD } from '../utils/helper';

import '../style/datePicker.css';

function ReportSetDate(props) {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [fileId, setFileId] = useState('');

  function handleSelect(date) {
    setSelectedDate(date);
    // eslint-disable-next-line react/destructuring-assignment
    props.onYearMonthSelected(date);
  }

  useEffect(() => {
    // Abort if data is empty
    if (props.dates && props.dates.length === 0) {
      return;
    }

    // const { fileIdUploaded } = props;

    // // Same file. Do nothing
    // if (fileId === fileIdUploaded) {
    //   return;
    // }

    // console.log(dates);
    // Populate dropdown with options if there is data
    setDates(props.dates);
    // if (props.dates.length === 1) {
    // handleSelect(dates[0]);
    // }

    // Reset dates when a new file is selected
    // if (fileId !== fileIdUploaded) {
    //   setFileId(fileIdUploaded);
    //   handleSelect(null);
    // }
  }, [props]);

  return (
    <Dropdown onSelect={(eventKey) => handleSelect(eventKey)} style={{ marginLeft: '0', marginRight: '0' }}>
      {/* <Dropdown.Header>Mês de referência</Dropdown.Header> */}
      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" disabled={!dates || dates.length === 0}>
        {selectedDate || 'Mês de referência '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dates && dates.length > 0
          ? (
            <>
              <Dropdown.Item eventKey={null}>Mês de referência</Dropdown.Item>
              <Dropdown.Divider />
              {dates.map((date) => <Dropdown.Item key={date} eventKey={date}>{date}</Dropdown.Item>)}
            </>
          ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ReportSetDate;
