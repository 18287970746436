/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Table } from 'react-bootstrap';
// import { cleanTableData, headersTable, skipColumnsTable } from '../utils/helper';
import { getFilteredTableColumns, getHeaders } from '../utils/dataHelper';

function ReportTable(props) {
  const [rawData, setRawData] = useState([]);
  const [tableData, setTableData] = useState([]);

  // useEffect(() => {
  //   const { data } = props;

  //   setRawData(data);
  // }, [props]);

  useEffect(() => {
    // Clean table data
    const td = getFilteredTableColumns(props.data, true); // cleanTableData(data, selectedYearMonth, skipColumnsTable);

    setTableData(td);
  }, [props]);

  return (
    <div>
      {tableData && tableData.length > 0
        ? (
          <Table responsive>
            <thead style={{ background: '#80808017' }}>
              <tr>
                {getHeaders().map((header) => <th key={header}>{header}</th>)}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => (
                <tr key={item.toString()}>
                  {item.map((cell, index) => (
                    <td key={index.toString()}>{cell || ''}</td>))}
                </tr>
                // )
              ))}
            </tbody>
          </Table>
        ) : null}
    </div>
  );
}

export default ReportTable;
