/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Table, Button } from 'react-bootstrap';
// import { cleanTableData, headersTable, skipColumnsTable } from '../utils/helper';
import { getFilteredTableColumns, getHeaders, numberToBRSCurrency } from '../utils/dataHelper';

function ReportExtraCostsTable(props) {
  const [costs, setCosts] = useState([]);

  useEffect(() => {
    const { data } = props;
    setCosts(data);
  }, [props]);

  const handleDeleteField = (index) => {
    const newCosts = JSON.parse(JSON.stringify(costs));
    newCosts.splice(index, 1);
    props.extraFeesChanged(newCosts);
    // setCosts(newCosts);
  };

  // useEffect(() => {
  //   console.log('costs updated');
  //   console.log(costs);
  //   props.extraFeesChanged(costs);
  // }, [costs]);

  return (
    <div>
      <Table responsive>
        <thead style={{ background: '#80808017' }}>
          <tr>
            <th>Descrição</th>
            <th>Valor</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {costs && costs.length !== 0
            ? costs.map((cost, index) => (
              <tr key={index}>
                <td width="70%">
                  {cost.name}
                </td>
                <td width="20%">
                  {numberToBRSCurrency(cost.value)}
                </td>
                <td width="10%">
                  <Button
                    variant="light"
                    onClick={() => props.extraFeesDeleted(index)}
                  >
                    Apagar
                  </Button>
                </td>
              </tr>
            )) : null}
        </tbody>
      </Table>
    </div>
  );
}

export default ReportExtraCostsTable;
