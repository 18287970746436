/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import '../style/service.css';

function Service(props) {
  return (
    <div className="service">
      <div className="card">
        <div className="bubble">
          <FontAwesomeIcon icon={props.icon} />
        </div>
        <h6 className="title">{props.title}</h6>
        <p className="description">
          {props.description}
        </p>
      </div>
      <div className="div-flex div-flex-center">
        {props.tags && props.tags.map((tag) => (
          <span className="tag">
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
}

export default Service;
