const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_signin',
    // editProfile: 'B2C_1_EditProfile',
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://seustudio.b2clogin.com/seustudio.onmicrosoft.com/b2c_1_signin',
    },
    // editProfile: {
    //   authority: 'https://contoso.b2clogin.com/contoso.onmicrosoft.com/Your-B2C-EditProfile-Policy-Id',
    // },
  },
  authorityDomain: 'seustudio.b2clogin.com',
};

export default b2cPolicies;
