/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */

import { REPORT_CLIENT } from '../sidemenu';

const DATE_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/;

const expectedCSVColumnsClient = [
  'Código de confirmação',
  'Status',
  'Nome do hóspede',
  'Entrar em contato',
  'Nº de adultos',
  'Nº de crianças',
  'Nº de bebês',
  'Data de início',
  'Data de término',
  'Nº de noites',
  'Reservado',
  'Anúncio',
  'Ganhos'];

const expectedCSVColumnsSocio = [
  'Guest name',
  'Contact',
  '# of adults',
  '# of children',
  '# of infants',
  'Start date',
  'End date',
  '# of nights',
  'Booked',
  'Listing',
  'Earnings',
];

const validateFile = (type, data) => {
  /* data.map((v, i) => {
    console.log(expectedCSVColumnsClient[i]);
    console.log(v);
  }); */
  if (type === REPORT_CLIENT) {
    if (data.length !== expectedCSVColumnsClient.length) {
      return false;
    } if (!data.every((value, index) => value === expectedCSVColumnsClient[index])) {
      return false;
    }
    return true;
  }
  if (data.length !== expectedCSVColumnsSocio.length) {
    return false;
  } if (!data.every((value, index) => value === expectedCSVColumnsSocio[index])) {
    return false;
  }
  return true;
};

const dateToString = (date) => {
  if (!date) { return; }
  if (!(date instanceof Date)) { return; }

  const day = date.getDate().toString().padStart(2, '0'); // get day and add leading zero if needed
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // get month and add leading zero if needed
  const year = date.getFullYear().toString(); // get year
  const dateString = `${day}/${month}/${year}`;
  return dateString;
};

const outputTable = [
  { name: 'Plataforma', currency: false },
  { name: 'Unidade', currency: true },
  { name: 'Código de Confirmação', currency: false },
  { name: 'Check-In', currency: false },
  { name: 'Check-Out', currency: false },
  { name: '# de noites', currency: false },
  { name: 'Hóspede', currency: false },
  { name: 'Valor da estadia', currency: true },
  { name: 'Taxa de Limpeza', currency: true },
  { name: 'Taxa de Administração', currency: true },
];

const getHeaders = () => outputTable.map((i) => i.name);

const stringToDate = (dateStr) => {
  if (!dateStr) { return; }
  if (!DATE_REGEX.test(dateStr)) { return; } // Check format MM/dd/YYYY

  let dateParts = '';
  let month = '';
  let day = '';
  let year = '';

  dateParts = dateStr.split('/');
  month = dateParts[1].padStart(2, '0');
  day = dateParts[0].padStart(2, '0');
  year = dateParts[2];

  return new Date(year, month - 1, day);
};

const numberOfNights = (checkIn, checkOut) => {
  if (!checkIn || !checkOut) { return 0; }

  const timeDiff = Math.abs(checkIn.getTime() - checkOut.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return diffDays;
};

const numberToCurrency = (value) => Intl.NumberFormat(
  'pt-BR',
  {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: 'BRL',
  },
).format(value);

const buildData = (
  guest,
  checkInDate,
  checkOutDate,
  nights,
  value,
  cleaningFee,
  unit,
  administrationFee,
  reservationCode,
  listing,
) => {
  // VALIDATE: Date is expected as Date
  const obj = {
    confirmation: {
      name: 'Código de Confirmação',
      currency: false,
      value: reservationCode || 'Not available', // TODO:
      stringValue: reservationCode || 'Not available', // TODO:
      show: true,
      tableOrder: 2,
    },
    listing: {
      name: 'Listing',
      currency: false,
      value: listing || 'Not applicable', // TODO:
      stringValue: listing || 'Not applicable', // TODO:
      show: false,
      tableOrder: null,
    },
    unit: {
      name: 'Apelido da unidade',
      currency: false,
      value: unit,
      stringValue: unit,
      show: true,
      tableOrder: 1,
    },
    yearMonth: {
      name: 'Ano/mes',
      currency: false,
      value: `${checkInDate.getFullYear()}/${checkInDate.getMonth() + 1}`,
      stringValue: `${checkInDate.getFullYear()}/${checkInDate.getMonth() + 1}`,
      show: false,
      tableOrder: null,
    },
    checkin: {
      name: 'Check-In',
      currency: false,
      value: dateToString(checkInDate),
      stringValue: dateToString(checkInDate),
      show: true,
      date: checkInDate,
      tableOrder: 3,
    },
    night: {
      name: '# de noites',
      currency: false,
      value: nights,
      stringValue: nights.toString(),
      show: true,
      tableOrder: 5,
    },
    guest: {
      name: 'Hóspede',
      currency: false,
      value: guest,
      stringValue: guest,
      show: true,
      tableOrder: 6,
    },
    amount: {
      name: 'Valor da estadia',
      currency: true,
      value,
      stringValue: numberToCurrency(parseFloat(value).toFixed(2)),
      show: true,
      tableOrder: 7,
    },
    cleaningFee: {
      name: 'Taxa de Limpeza',
      currency: true,
      value: parseFloat(cleaningFee),
      stringValue: numberToCurrency(parseFloat(cleaningFee).toFixed(2)),
      show: true,
      tableOrder: 8,
    },
    checkout: {
      name: 'Check-Out',
      currency: false,
      value: dateToString(checkOutDate),
      stringValue: dateToString(checkOutDate),
      show: true,
      date: checkOutDate,
      tableOrder: 4,
    },
    adminFee: {
      name: 'Taxa de Administração',
      currency: true,
      value: parseFloat(((Number(administrationFee) * value) / 100).toFixed(2)),
      stringValue: numberToCurrency(parseFloat(((Number(administrationFee) * value) / 100).toFixed(2))),
      show: true,
      tableOrder: 9,
    },
  };
  return obj;
};

const buildManualData = (guest, checkInDate, checkOutDate, nights, value, cleaningFee, unit, administrationFee) => {
  let obj = buildData(guest, checkInDate, checkOutDate, nights, value, cleaningFee, unit, administrationFee);
  obj = {
    ...obj,
    source: {
      name: 'manual',
      currency: false,
      value: 'Manual',
      stringValue: 'Manual',
      show: true,
      tableOrder: 0,
    },
  };
  return obj;
};

const getUniqueDates = (data) => {
  if (!data) { return; }

  const dateSet = new Set();
  // Add all 'yearMonth' fields to the set
  data.map((i) => i.yearMonth.value).map((ym) => dateSet.add(ym));

  // convert to array and sort descending
  const dateArray = Array.from(dateSet).sort().reverse();
  return dateArray;
};

const getUniqueListings = (data) => {
  if (!data) { return; }

  const listingSet = new Set();
  // Add all 'yearMonth' fields to the set
  data.map((i) => i.listing.value).map((ym) => listingSet.add(ym));

  // convert to array and sort descending
  const listingArray = Array.from(listingSet).sort().reverse();
  return listingArray;
};

const getFilteredTableColumns = (data, stringOnly) => {
  if (!data || data.length === 0) return;

  const result = [];
  Object.values(data).forEach((obj) => {
    const res = [];
    Object.entries(obj).forEach((entry) => {
      if (entry[1].show) {
        let value;
        if (stringOnly) {
          value = entry[1].currency ? (`R$ ${entry[1].stringValue}`) : entry[1].stringValue;
          // res.push(entry[1].currency ? (`R$ ${entry[1].stringValue}`) : entry[1].stringValue);
        } else {
          value = entry[1].value;
          // res.push(entry[1].value);
        }
        res[entry[1].tableOrder] = value;
      }
    });

    result.push(res);
  });
  return result;
};

const getFilteredRowsByDate = (data, dateStr) => {
  let dataCopy = { ...data };
  dataCopy = Object.values(dataCopy).filter((row) => row.yearMonth.value === dateStr);
  return dataCopy;
};

const getFilteredRowsByListing = (data, listing) => {
  let dataCopy = { ...data };
  dataCopy = Object.values(dataCopy).filter((row) => row.listing.value === listing);
  return dataCopy;
};

const numberToBRSCurrency = (value) => `R$ ${numberToCurrency(value)}`;

const convertAllToString = (data) => getFilteredTableColumns(data, true);

const calculateSubtotalValue = (data) => {
  if (!data || data.length === 0) return 0;

  const adminFee = Object.values(data).map((i) => i.adminFee.value);
  const adminFeeSum = adminFee.reduce((a, b) => a + b);
  const cleaningFee = Object.values(data).map((i) => i.cleaningFee.value);
  const cleaningFeeSum = cleaningFee.reduce((a, b) => a + b);

  let total = cleaningFeeSum + adminFeeSum;

  total = Math.round(total * 100) / 100;
  return total;
};

const calculateSubtotal = (data, currency) => {
  const total = calculateSubtotalValue(data, currency);
  return currency ? numberToBRSCurrency(total) : numberToCurrency(total);
};

const calculateBookingTotalValue = (data) => {
  if (!data || data.length === 0) return 0;

  const booking = Object.values(data).map((i) => i.amount.value);
  const bookingSum = booking.reduce((a, b) => a + b);

  const adminFee = Object.values(data).map((i) => i.adminFee.value);
  const adminFeeSum = adminFee.reduce((a, b) => a + b);

  const cleaningFee = Object.values(data).map((i) => i.cleaningFee.value);
  const cleaningFeeSum = cleaningFee.reduce((a, b) => a + b);

  let total = bookingSum + cleaningFeeSum + adminFeeSum;

  total = Math.round(total * 100) / 100;
  return total;
};

const calculateBookingTotal = (data, currency) => {
  const total = calculateBookingTotalValue(data, currency);
  return currency ? numberToBRSCurrency(total) : numberToCurrency(total);
};

const calculateAdminFee = (data, percentFee) => {
  if (!percentFee) {
    percentFee = 0;
  }
  if (!data) {
    return undefined;
  }
  const copy = [];
  data.forEach((row) => {
    const obj = {
      ...row,
      adminFee: {
        ...row.adminFee,
        value: parseFloat(((Number(percentFee) * row.amount.value) / 100).toFixed(2)),
        stringValue: numberToCurrency(parseFloat(((Number(percentFee) * row.amount.value) / 100).toFixed(2))),
      },
    };
    copy.push(obj);
  });
  return copy;
};

const calculateCleaningFee = (data, cleaningFee) => {
  if (!cleaningFee) {
    cleaningFee = 0;
  }
  if (!data) {
    return undefined;
  }
  const copy = [];
  data.forEach((row) => {
    const obj = {
      ...row,
      amount: {
        ...row.amount,
        value: row.amount.value + row.cleaningFee.value - cleaningFee,
        stringValue: numberToCurrency(parseFloat(row.amount.value + row.cleaningFee.value - cleaningFee).toFixed(2)),
      },
      cleaningFee: {
        ...row.cleaningFee,
        value: cleaningFee,
        stringValue: numberToCurrency(parseFloat(cleaningFee).toFixed(2)),
      },
    };
    copy.push(obj);
  });
  return copy;
};

const getListing = (data) => {
  if (!data || data.length === 0) return;

  const dateSet = new Set();
  // Add all 'yearMonth' fields to the set
  data.map((i) => i.listing.value).map((ym) => dateSet.add(ym));

  // convert to array and sort descending
  const dateArray = Array.from(dateSet);
  return dateArray;
};

const getUnit = (data) => {
  if (!data || data.length === 0) return;

  const dateSet = new Set();
  data.map((i) => i.unit.value).map((ym) => dateSet.add(ym));

  // convert to array and sort descending
  const dateArray = Array.from(dateSet);
  return dateArray;
};

export {
  dateToString, numberOfNights, buildManualData, buildData, stringToDate,
  getUniqueDates, getFilteredRowsByListing, getUniqueListings, getFilteredTableColumns, getHeaders, calculateAdminFee, calculateCleaningFee,
  getFilteredRowsByDate, getListing, getUnit, calculateSubtotalValue,
  calculateSubtotal, convertAllToString, numberToCurrency, numberToBRSCurrency, validateFile, calculateBookingTotal, calculateBookingTotalValue,
  expectedCSVColumnsClient as expectedCSVColumns,
};
