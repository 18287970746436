/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  InputGroup, Form, Button, Accordion, Container,
} from 'react-bootstrap';
import ContractExport from '../components/ContractExport';
import { clausulas } from '../utils/contract';
import ContractForms from '../components/ContractForms';

import '../style/portalPage.css';

function Contract() {
  const [currentForm, setCurrentForm] = useState(undefined);
  const [contractParts, setContractParts] = useState('');
  const [adminFee, setAdminFee] = useState('');
  const [formsEnabled, setFormsEnabled] = useState(true);
  const [clauses, setClauses] = useState(clausulas);
  const [name, setName] = useState('');
  const [exportEnabled, setExportEnabled] = useState(false);

  const handleFormChange = (form) => {
    setCurrentForm(form);
  };

  const updateClause = (fromClauses, clauseNumber, contentNumber, replaceFrom, replaceTo) => {
    const originalContent = fromClauses[clauseNumber].content[contentNumber];
    const updatedContent = originalContent.replace(replaceFrom, replaceTo);

    const updatedClause = {
      ...fromClauses[clauseNumber],
      content: [...fromClauses[clauseNumber].content.slice(0, contentNumber), updatedContent, ...fromClauses[clauseNumber].content.slice(contentNumber + 1)],
    };
    const newClause = [...fromClauses.slice(0, clauseNumber), updatedClause, ...fromClauses.slice(clauseNumber + 1)];
    return newClause;
  };

  const replaceClause = (fromClauses, clauseNumber, contentNumber, newContent) => {
    const updatedContent = newContent;

    const updatedClause = {
      ...fromClauses[clauseNumber],
      content: [...fromClauses[clauseNumber].content.slice(0, contentNumber), updatedContent, ...fromClauses[clauseNumber].content.slice(contentNumber + 1)],
    };
    const newClause = [...fromClauses.slice(0, clauseNumber), updatedClause, ...fromClauses.slice(clauseNumber + 1)];
    return newClause;
  };

  // useEffect(() => {
  //   if (!currentForm) {
  //     console.log('Formulario ainda nao selecionado!');
  //     return;
  //   }

  //   const newClause = updateClause(clausulas, 3, 3, '${taxa}', adminFee);
  //   setClauses(newClause);
  // }, [adminFee]);

  useEffect(() => {
    if (!currentForm || Object.keys(currentForm).length === 0) {
      setContractParts('Selecione formulário...');
      return;
    }

    const {
      nome,
      nascimento,
      nacionalidade,
      rg,
      cpf,
      estadoCivil,
      profissao,
      email,
      telefone,
      endereco,
      pagamento,
      taxa,
    } = currentForm;

    const parts = `Gustavo Moreira Vaccari, representante legal e operacional da empresa de nome fantasia SEU STUDIO, registrado sob o CPF nº 110.624.667 - 56, com endereço nesta Capital, Estado de São Paulo, na Rua Cônego Vicente Miguel Marino, 161 - CEP 01135-020, Barra Funda, neste ato doravante denominada simplesmente PRESTADOR (A) e doravante referido PROPRIETÁRIO ou RESPONSÁVEL PELO IMÓVEL:
    
${nome}, nascido(a) em ${nascimento}, ${nacionalidade}, ${estadoCivil}, portador(a) do RG: ${rg}, CPF: ${cpf}, ${profissao}, proprietário(a) e/ou responsável do imóvel situado na ${endereco}. E-mail: ${email} e telefone: ${telefone}

CONTA PARA PAGAMENTO:
${pagamento}`;

    setAdminFee(taxa);
    setContractParts(parts);
    setName(nome);
    setExportEnabled(true);
  }, [currentForm]);

  const handleChangeParts = (event) => {
    setFormsEnabled(false);
    setContractParts(event.target.value);
  };

  const handleChangeClause = (value, clauseIndex, contentIndex) => {
    const newClause = replaceClause(clausulas, clauseIndex, contentIndex, value);
    setClauses(newClause);
  };

  return (
    <>
      <div className="top-bar" />
      <h3 className="portal-title">Termo de Adesão</h3>
      <ContractForms
        style={{ marginTop: '2rem', width: 'fit-content' }}
        onChange={handleFormChange}
        enabled={formsEnabled}
      />
      <ContractExport
        className="action-button"
        style={{
          width: '15rem', position: 'relative', float: 'right', marginBottom: '2rem',
        }}
        parts={contractParts}
        clauses={updateClause(clauses, 3, 3, '${taxa}', adminFee)}
        name={name}
        enabled={exportEnabled}
      />
      <h6 style={{ marginTop: '2rem' }}> DAS PARTES </h6>
      <InputGroup style={{ height: '25vh', marginTop: '2rem' }}>
        <Form.Control
          as="textarea"
          aria-label="With textarea"
          defaultValue={contractParts}
          onChange={handleChangeParts}
        />
      </InputGroup>

      {/* {clauses.map((clause) => (
        <>
          <h6 style={{ marginTop: '2rem' }}>
            {clause.title}
          </h6>

          {clause.content.map((c) => (
            <p>
              {c}
            </p>
          ))}

        </>
      ))} */}

      {clauses.map((clause, clauseIndex) => (
        <>
          <h6 style={{ marginTop: '2rem' }}>
            {clause.title}
          </h6>

          {clause.content.map((c, contentIndex) => (
            <InputGroup style={{ height: '20vh', marginTop: '2rem' }}>
              <Form.Control
                as="textarea"
                aria-label="With textarea"
                defaultValue={c}
                onChange={(event) => handleChangeClause(event.target.value, clauseIndex, contentIndex)}
              />
            </InputGroup>
          ))}

        </>
      ))}

      {/* <h6 style={{ marginTop: '2rem' }}>
        {clausulas[0].title}
      </h6>
      <InputGroup style={{ height: '20vh', marginTop: '2rem' }}>
        <Form.Control
          as="textarea"
          aria-label="With textarea"
          defaultValue={clausulas[0].content}
        />
      </InputGroup>

      <h6 style={{ marginTop: '2rem' }}>
        {clausulas[1].title}
      </h6>
      <InputGroup style={{ height: '20vh', marginTop: '2rem' }}>
        <InputGroup.Text>1</InputGroup.Text>
        <Form.Control
          as="textarea"
          aria-label="With textarea"
          defaultValue={clausulas[1].content}
        />
      </InputGroup>
      <h6 style={{ marginTop: '2rem' }}>
        {clausulas[2].title}
      </h6>
      <InputGroup style={{ height: '30vh', marginTop: '2rem' }}>
        <InputGroup.Text>2</InputGroup.Text>
        <Form.Control
          as="textarea"
          aria-label="With textarea"
          defaultValue={clausulas[2].content}
        />
      </InputGroup>
      <h6 style={{ marginTop: '2rem' }}>
        {clausulas[3].title}
      </h6>
      <InputGroup style={{ height: '30vh', marginTop: '2rem' }}>
        <InputGroup.Text>3</InputGroup.Text>
        <Form.Control
          as="textarea"
          aria-label="With textarea"
          defaultValue={clausulas[3].content}
        />
      </InputGroup> */}
    </>
  );
}

export default Contract;
