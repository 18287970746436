const listings = [
  {
    title: 'Lindo Apê na Barra Funda/Sta. Cecília/Bom Retiro',
    image: 'listings/listing1.jpg',
    location: 'Barra Funda',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/46638966',
  },
  {
    title: 'Loft de Luxo entre a Frei Caneca e Parque Augusta',
    image: 'listings/listing2.jpg',
    location: 'Consolação',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/49673711',
  },
  {
    title: 'Loft Completo | Pinheiros | Metrô Oscar Freire',
    image: 'listings/listing3.jpg',
    location: 'Pinheiros',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com/rooms/798349649677518924',
  },
  {
    title: 'Loft Luxuoso | Vila Mariana | Ibirapuera',
    image: 'listings/listing4.jpg',
    location: 'Vila Mariana',
    guests: '3 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/755158457955257441',
  },
  {
    title: 'Raridade, Prático e Confortável | Vila Mariana',
    image: 'listings/listing5.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/763142933394442061',
  },
  {
    title: 'Luxo e Sofisticação na Vila Mariana / Clementino',
    image: 'listings/listing6.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/813646702739464954',
  },
  {
    title: 'O mais lindo da região: Piscina, Ar e próx. metrô!',
    image: 'listings/listing7.jpg',
    location: 'Vila Mariana',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/778852483815388467',
  },
  {
    title: 'Loft 21 | Maior Arranha Céus de SP (Sampa Sky)',
    image: 'listings/listing8.jpg',
    location: 'Centro',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/689052674074616822',
  },
  {
    title: 'Loft na Vila Mariana: Metrô | Piscina | Academia',
    image: 'listings/listing9.jpg',
    location: 'Vila Mariana',
    guests: '3 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/882778014863216325',
  },
  {
    title: 'Flat Luxo: Anhembi, Braz Leme e Expo Center Norte',
    image: 'listings/listing10.jpg',
    location: 'Anhembi',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/733356472795492745',
  },
  {
    title: 'Lugar Especial: perto de tudo! Metrô e com piscina',
    image: 'listings/listing11.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/765416072016388608',
  },
  {
    title: 'Melhor lugar da Vila Mariana | Pq. Ibira e Metrô',
    image: 'listings/listing12.jpg',
    location: 'Vila Mariana',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/737024604284772868',
  },
  {
    title: 'Sua casa em SP | Pinheiros | Metrô Oscar Freire',
    image: 'listings/listing13.jpg',
    location: 'Pinheiros',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/821813242505887105',
  },
  {
    title: 'Loft Luxo no Metrô Moema | Pq Ibirapuera',
    image: 'listings/listing14.jpg',
    location: 'Moema',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/702758072861895009',
  },
  {
    title: 'Loft Lindo c/ Ar condicionado + Piscina + Metrô',
    image: 'listings/listing15.jpg',
    location: 'Vila Mariana',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/756552366660350031',
  },
  {
    title: 'Alto Padrão com Varanda, Ar Condicionado e Piscina',
    image: 'listings/listing16.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/862510989125344774',
  },
  {
    title: 'Seu Cantinho Especial na Vila Mariana',
    image: 'listings/listing17.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/768209075922084255',
  },
  {
    title: 'Loft de Luxo c/Piscina, Academia e Mercado 24h',
    image: 'listings/listing18.jpg',
    location: 'Vila Clementino',
    guests: '3 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/756619458467356776',
  },
  {
    title: 'Flat Inteligente na Vila Mariana | Metrô',
    image: 'listings/listing19.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/770291473580018603',
  },
  {
    title: 'Prático e Confortável | V. Mariana | Metrô',
    image: 'listings/listing20.jpg',
    location: 'Vila Mariana',
    guests: '3 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/823344178181642854',
  },
  {
    title: 'Loft 18 º Andar c/ Piscina e vista pro Ibirapuera',
    image: 'listings/listing21.jpg',
    location: 'Vila Clementino',
    guests: '3 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/766693034189416411',
  },
  {
    title: 'Apto 1 dorm. Frei Caneca',
    image: 'listings/listing22.jpg',
    location: 'Consolação',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/891876359542754693',
  },
  {
    title: 'Sartorato 107 A London Residence',
    image: 'listings/listing23.jpg',
    location: 'Consolação',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/856671552724958272',
  },
  {
    title: 'Loft Pinheiros, Metrô Oscar Freire, Av. Rebouças',
    image: 'listings/listing24.jpg',
    location: 'Pinheiros',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/821865730902028537',
  },
  {
    title: 'Loft: 5 Stars em Pinheiros | Metrô Oscar Freire',
    image: 'listings/listing25.jpg',
    location: 'Pinheiros',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/716001413213026681',
  },
  {
    title: 'Flat Novíssimo e funcional, ao lado do Metrô!',
    image: 'listings/listing26.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/806655385966292863',
  },
  {
    title: 'Cobertura na Frei Caneca | Vista linda da Paulista',
    image: 'listings/listing27.jpg',
    location: 'Bela Vista',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/52600779',
  },
  {
    title: 'Raridade na Frei Caneca | Augusta | Paulista',
    image: 'listings/listing28.jpg',
    location: 'Bela Vista',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/797931057061478275',
  },
  {
    title: 'Conforto na Frei Caneca, R. Augusta, Av. Paulista',
    image: 'listings/listing29.jpg',
    location: 'Bela Vista',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/821865399675016649',
  },
  {
    title: 'Novíssimo, piscina na cobertura, pertinho do Metrô',
    image: 'listings/listing30.jpg',
    location: 'Vila Clementino',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/755536789658273275',
  },
  {
    title: 'Loft 33º andar no maior prédio de SP | Sampa Sky',
    image: 'listings/listing31.jpg',
    location: 'Centro',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/596350750392424317',
  },
  {
    title: 'Luxo e Aconchego em Pinheiros | Metrô Oscar Freire',
    image: 'listings/listing32.jpg',
    location: 'Pinheiros',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/831112509856730334',
  },
  {
    title: 'Loft aconchegante com Piscina e Metrô Vila Mariana',
    image: 'listings/listing33.jpg',
    location: 'Vila Mariana',
    guests: '3 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/751468655218762885',
  },
  {
    title: 'Aconchego em SP | Metrô | Vila Mariana | Piscina',
    image: 'listings/listing34.jpg',
    location: 'Vila Mariana',
    guests: '3 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/822554345113682502',
  },
  {
    title: 'Super localizado, lindo e aconchegante',
    image: 'listings/listing35.jpg',
    location: 'Pinheiros',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/866605341278286610',
  },
  {
    title: 'Sua Casa em SP: Com piscina e pertinho do metrô!',
    image: 'listings/listing36.jpg',
    location: 'Vila Clementino',
    guests: '4 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/768279185903894729',
  },
  {
    title: 'Excelente | Vila Mariana | Metrô | Piscina',
    image: 'listings/listing37.jpg',
    location: 'Vila Mariana',
    guests: '2 Hóspedes',
    link: 'https://www.airbnb.com.br/rooms/821865850042836355',
  },
];

export default listings;
