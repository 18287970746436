import React, { useState, useEffect } from 'react';
import Report from './Report';
// import ErrorPage from '../components/ErrorPage';
import Sidebar from '../components/Sidebar';
import ReportSocios from './ReportSocios';
import Contract from './Contract';
import { REPORT_CLIENT, REPORT_SOCIO, CONTRACT } from '../sidemenu';

const MIN_SCREEN_SIZE = 768;

function Portal() {
  const [selectedOption, setSelectedOption] = useState('ReportClients');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < MIN_SCREEN_SIZE); // Set breakpoint as needed
    };

    // Call handleResize initially and whenever the window is resized
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  let content;
  if (selectedOption === REPORT_CLIENT) {
    content = <Report />;
  } else if (selectedOption === REPORT_SOCIO) {
    content = <ReportSocios />;
  } else if (selectedOption === CONTRACT) {
    content = <Contract />;
  } else {
    content = <Report />;
  }

  return (
    <div>
      {isSmallScreen
        ? (
          <p
            style={{ textAlign: 'center', marginTop: '2rem' }}
          >
            Portal compatível com computador
          </p>
        )
        : (
          <div>
            <Sidebar
              options={['Reports']}
              selectedOption={selectedOption}
              onOptionSelect={handleOptionSelect}
            />
            <div style={{
              marginLeft: '16rem',
              padding: '3rem',
              background: 'rgb(237 237 237 / 40%)',
              marginTop: '10vh',
              minHeight: '90vh',
            }}
            >
              {content}
            </div>
          </div>
        )}
    </div>
  );
}

export default Portal;
