const clients = [
  // {
  //   unit: ['P1015', 'P1006'],
  //   name: 'Ana Paula & Christian',
  //   nickname: 'Ana',
  //   email: 'anatest@email.com',
  // },
];

export default clients;
