/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import pix from '../utils/pix';
import {
  getListing, getUnit, getHeaders, convertAllToString, calculateSubtotalValue, calculateSubtotal, numberToBRSCurrency,
} from '../utils/dataHelper';

import '../style/Roboto-Thin-normal';
import '../style/Roboto-Regular-normal';
import '../style/Roboto-Bold-normal';

import logo from '../media/logo-white.png';

const QR_CODE_SIZE = 35;
const BORDER_X = 16;
const BORDER_Y = 20;
const SPACE_AFTER_TITLE = 5;
const SPACE_AFTER_CLAUSE = 10;

function ReportDataExport(props) {
  const pdf = new jsPDF();
  const START_Y = BORDER_Y + 40;

  const handleExport = () => {
    // Create jsPDF
    // eslint-disable-next-line new-cap
    const pageWidth = pdf.getPageWidth();
    pdf.setFillColor(18, 77, 84);
    pdf.rect(0, 0, pageWidth, BORDER_Y + 20, 'F');

    // Title
    pdf.setFont('Roboto-Bold');
    pdf.setFontSize(22);
    pdf.setTextColor(255, 255, 255);
    pdf.text('TERMO DE ADESÃO', BORDER_X, BORDER_Y);

    // Information
    pdf.setFontSize(13);
    pdf.setFont('Roboto-Thin');
    pdf.text('CONTRATO DE ADMINISTRAÇÃO POR TEMPORADA', BORDER_X, BORDER_Y + 6);
    pdf.setTextColor(0, 0, 0);

    // LOGO
    pdf.addImage(logo, 'JPEG', pageWidth - 2.5 * BORDER_X, 14, 24, 8);

    pdf.setFontSize(12);
    // const startTable = pdf.autoTable.previous.finalY + 5;
    let startTable = START_Y;

    const headStyles = {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
    };
    // DAS PARTES
    // CLAUSE TITLE
    pdf.setFont('Roboto-Bold');
    pdf.text('DAS PARTES', BORDER_X, startTable);
    startTable += SPACE_AFTER_TITLE; // space after title

    const b = [];
    b.push([props.parts]);
    autoTable(pdf, {
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      startY: startTable,
      body: b,
      didParseCell(table) {
        if (table.section === 'body') {
          table.cell.styles.textColor = '#000';
          table.cell.styles.font = 'Roboto-Regular';
          table.cell.styles.fillColor = 'white';
        }
      },
    });
    startTable = pdf.autoTable.previous.finalY + SPACE_AFTER_CLAUSE;

    // CLAUSES
    props.clauses.forEach((clause) => {
      // CLAUSE TITLE
      pdf.setFont('Roboto-Bold');
      pdf.text(`${clause.title}`, BORDER_X, startTable);
      startTable += SPACE_AFTER_TITLE; // space after title

      const body = [];

      // CLAUSE CONTENT
      clause.content.forEach((subclause) => {
        body.push([subclause]);
      });

      autoTable(pdf, {
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
        startY: startTable,
        body,
        didParseCell(table) {
          if (table.section === 'body') {
            table.cell.styles.textColor = '#000';
            table.cell.styles.font = 'Roboto-Regular';
            table.cell.styles.fillColor = 'white';
          }
        },
      });

      startTable = pdf.autoTable.previous.finalY + SPACE_AFTER_CLAUSE;
    });

    pdf.setFont('Roboto-Regular');
    startTable += 20;
    pdf.text('_____________________________________________', BORDER_X, startTable);
    startTable += 5;
    pdf.setFontSize(11);
    pdf.text('Gustavo Moreira Vaccari', BORDER_X, startTable);

    startTable += 30;
    pdf.text('_____________________________________________', BORDER_X, startTable);
    startTable += 5;
    pdf.setFontSize(11);
    pdf.text(props.name, BORDER_X, startTable);

    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = currentDate.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    startTable += 30;
    pdf.setFontSize(11);
    pdf.text(`São Paulo, ${formattedDate}`, BORDER_X, startTable);

    // PAGINATION
    const pages = pdf.internal.getNumberOfPages();
    const w = pdf.internal.pageSize.width; // Optional
    const h = pdf.internal.pageSize.height; // Optional
    pdf.setFontSize(10); // Optional

    for (let j = 1; j < pages + 1; j++) {
      const horizontalPos = w / 2; // Can be fixed number
      const verticalPos = h - 10; // Can be fixed number
      pdf.setPage(j);
      pdf.setFont('Roboto-Thin');
      pdf.text(`${j} / ${pages}`, horizontalPos, verticalPos, { align: 'center' }); // Optional text styling});
    }

    pdf.save(props.name);
  };

  return (
    // <div style={{ marginTop: '1em', ...props.style }}>
    <Button
      className={props.className}
      variant="success"
      onClick={handleExport}
      disabled={!props.enabled}
    >
      Baixar Contrato
    </Button>
  // </div>
  );
}

export default ReportDataExport;
