/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form, InputGroup, Row, Col, Button, Table, Card, Modal,
} from 'react-bootstrap';
import {
  numberToBRSCurrency,
  numberOfNights, buildManualData, stringToDate,
} from '../utils/dataHelper';

import clients from '../clients';

import '../style/extraReservationModal.css';

const DATE_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/;

function ReportExtraCostsModal(props) {
  const [show, setShow] = useState(false);
  const [guest, setGuest] = useState('');
  const [checkInDate, setCheckInDate] = useState(undefined);
  const [checkOutDate, setCheckOutDate] = useState(undefined);
  const [checkInStr, setCheckInStr] = useState('');
  const [checkOutStr, setCheckOutStr] = useState('');
  const [checkInValid, setCheckInValid] = useState(true);
  const [checkOutValid, setCheckOutValid] = useState(true);
  const [value, setValue] = useState('');
  const [cleaningFee, setCleaningFee] = useState('');
  const [administrationFee, setAdministrationFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [nights, setNights] = useState(0);
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState('');

  const valueChange = (v) => {
    setValue(numberToBRSCurrency(v));
  };

  // Cleanup
  const resetModal = () => {
    setGuest('');
    setCheckInDate(undefined);
    setCheckOutDate(undefined);
    setCheckInStr('');
    setCheckOutStr('');
    setCheckInValid(true);
    setCheckOutValid(true);
    setValue('');
    setCleaningFee('');
    setUnit('');
  };

  const handleClose = () => {
    resetModal();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    setNights(numberOfNights(checkInDate, checkOutDate));
  }, [checkInDate, checkOutDate]);

  useEffect(() => {
    const { adminFee, filterClient } = props;
    setAdministrationFee(adminFee);

    // Show only current unit
    if (filterClient && filterClient.length === 1) {
      const filteredUnit = filterClient[0].unit;
      setUnits(filteredUnit);
    } else { // Show all units
      setUnits(clients.map((client) => client.unit).flat());
    }
  }, [props]);

  useEffect(() => {
    if (nights === 0 || value === 0) {
      setTotal(0);
    } else {
      setTotal(parseInt(nights, 10) * parseFloat(value));
    }
  }, [nights, value]);

  // /**
  //  * Converts date on format yyyy-MM-dd to actual Date object
  //  * @param {*} dateStr
  //  * @returns Date
  //  */
  // const stringToDate = (dateStr) => {
  //   if (!dateStr) { return; }

  //   let dateParts = '';
  //   let month = '';
  //   let day = '';
  //   let year = '';

  //   dateParts = dateStr.split('/');
  //   year = dateParts[2];
  //   month = dateParts[1].padStart(2, '0');
  //   day = dateParts[0].padStart(2, '0');

  //   return new Date(year, month - 1, day);
  // };

  const handleOnUnitChanged = (e) => {
    setUnit(e.target.value);
  };

  const handleAddField = () => {
    const obj = buildManualData(guest, checkInDate, checkOutDate, nights, total, cleaningFee, unit, administrationFee);
    console.log(obj);
    props.extraReservationAdded(obj);
    handleClose();
  };

  const handleCheckOut = (dateValue) => {
    if (dateValue === '') {
      setCheckOutValid(true);
    } else if (DATE_REGEX.test(dateValue)) {
      setCheckOutDate(stringToDate(dateValue));
      setCheckOutValid(true);
    } else {
      setCheckOutDate(undefined);
      setCheckOutValid(false);
    }
    setCheckOutStr(dateValue);
  };

  const handleCheckIn = (dateValue) => {
    if (dateValue === '') {
      setCheckInValid(true);
    } else if (DATE_REGEX.test(dateValue)) {
      setCheckInDate(stringToDate(dateValue));
      setCheckInValid(true);
    } else {
      setCheckInDate(undefined);
      setCheckInValid(false);
    }
    setCheckInStr(dateValue);
  };

  return (
    <div style={{
      flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
    }}
    >
      <Button variant="outline-success" onClick={handleShow} style={{ marginTop: 'auto' }}>
        Adicionar reserva
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Adicionar reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group controlId="guest" className="manualEntry">
            <Form.Label>Hóspede</Form.Label>
            <Form.Control
              type="text"
              value={guest}
              onChange={(e) => setGuest(e.target.value)}
            />
          </Form.Group> */}
          <Form.Group controlId="guest" className="manualEntry">
            <Form.Label>Unidade*</Form.Label>
            <Form.Select
              onChange={handleOnUnitChanged}
              value={unit}
            >
              <option>Escolher unidade</option>
              {units ? units.map((u) => <option key={u}>{u}</option>) : null}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="checkInDate" className="manualEntry">
            <Form.Label>Check-in*</Form.Label>
            <Form.Control
              type="text"
              required
              isInvalid={!checkInValid}
              placeholder="DD/MM/YYYY"
              value={checkInStr}
              onChange={(e) => handleCheckIn(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="checkOutDate" className="manualEntry">
            <Form.Label>Check-out*</Form.Label>
            <Form.Control
              type="text"
              required
              isInvalid={!checkOutValid}
              value={checkOutStr}
              placeholder="DD/MM/YYYY"
              onChange={(e) => handleCheckOut(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="value" className="manualEntry">
            <Form.Label>Valor da diária*</Form.Label>
            <Form.Control
              type="number"
              value={value}
              placeholder="0,00"
              onChange={(e) => setValue(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="cleaningFee" className="manualEntry">
            <Form.Label>Taxa de Limpeza*</Form.Label>
            <Form.Control
              type="number"
              value={cleaningFee}
              placeholder="0,00"
              onChange={(e) => setCleaningFee(e.target.value)}
            />
          </Form.Group>

          <Form.Label className="manualEntry" style={{ width: '100%', textAlign: 'right' }}>
            Número de noites:
            {' '}
            {nights}
          </Form.Label>
          <Form.Label style={{ width: '100%', textAlign: 'right' }}>
            Total da estadia: R$
            {' '}
            { total}
          </Form.Label>

          {/* <Form.Group controlId="administrationFee" className="manualEntry">
            <Form.Label>Taxa de Administracao</Form.Label>
            <Form.Control
              type="number"
              value={administrationFee}
              onChange={(e) => setAdministrationFee(e.target.value)}
            />
          </Form.Group> */}
          {/* <p>Em construção</p> */}
          {/* <Form>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group style={{ marginTop: '1rem' }}>
              <Form.Label>Valor</Form.Label>
              <Form.Control
                type="number"
                placeholder="R$ 0,00"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                required
                prefix="R$"
              />
            </Form.Group>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={handleAddField}
            disabled={checkInDate === undefined
              || checkOutDate === undefined
              || unit === ''
              || value === ''
            || cleaningFee === ''}
          >
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    // <Row>
    //   <Col md={4} xs={12}>
    //     <Card.Subtitle className="mb-2 text-muted">Adiciona detailes</Card.Subtitle>
  // <Form>
  //   <Form.Group>
  //     <Form.Label>Descrição</Form.Label>
  //     <Form.Control
  //       type="text"
  //       value={name}
  //       onChange={(e) => setName(e.target.value)}
  //       required
  //     />
  //   </Form.Group>
  //   <Form.Group>
  //     <Form.Label>Valor</Form.Label>
  //     <Form.Control
  //       type="number"
  //       value={value}
  //       onChange={(e) => setValue(e.target.value)}
  //       required
  //     />
  //   </Form.Group>
  //   <Button variant="success" style={{ marginTop: '1em', marginBottom: '1em' }} onClick={handleAddField} disabled={name === '' || value === ''}>Adicionar</Button>
  // </Form>
    //   </Col>
    //   <Col md={8} xs={12}>
    //     <Card.Subtitle className="mb-2 text-muted">Dados do Extra fees</Card.Subtitle>
    //     <Table striped bordered hover>
    //       <thead>
    //         <tr>
    //           <th>Descrição</th>
    //           <th>Valor</th>
    //           <th>Acao</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {fields.length !== 0
    //           ? fields.map((field, index) => (
    //             <tr key={index}>
    //               <td>
    //                 {fields[index].name}
    //               </td>
    //               <td>
    //                 {numberToBRSCurrency(fields[index].value / 100)}
    //               </td>
    //               <td>
    //                 <Button
    //                   variant="success"
    //                   onClick={() => handleDeleteField(index)}
    //                 >
    //                   Apagar
    //                 </Button>
    //               </td>
    //             </tr>
    //           )) : null}
    //       </tbody>
    //     </Table>
    //   </Col>
    // </Row>

  );
}

export default ReportExtraCostsModal;
