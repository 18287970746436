/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import '../style/datePicker.css';

function ReportSetListing(props) {
  const [listings, setListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState('');
  const [fileId, setFileId] = useState('');

  function handleSelect(listing) {
    setSelectedListing(listing);
    // eslint-disable-next-line react/destructuring-assignment
    props.onListingSelected(listing);
  }

  useEffect(() => {
    // Abort if data is empty
    if (props.listings && props.listings.length === 0) {
      return;
    }

    // const { fileIdUploaded } = props;

    // // Same file. Do nothing
    // if (fileId === fileIdUploaded) {
    //   return;
    // }

    // console.log(dates);
    // Populate dropdown with options if there is data
    setListings(props.listings);
    // if (props.dates.length === 1) {
    // handleSelect(dates[0]);
    // }

    // Reset dates when a new file is selected
    // if (fileId !== fileIdUploaded) {
    //   setFileId(fileIdUploaded);
    //   handleSelect(null);
    // }
  }, [props]);

  return (
    <Dropdown onSelect={(eventKey) => handleSelect(eventKey)} style={{ marginLeft: '0', marginRight: '0' }}>
      {/* <Dropdown.Header>Anúncio</Dropdown.Header> */}
      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" disabled={!listings || listings.length === 0}>
        {selectedListing || 'Mostrar todos anúncios '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {listings && listings.length > 0
          ? (
            <>
              <Dropdown.Item eventKey={null}>Mostrar todos anúncios</Dropdown.Item>
              <Dropdown.Divider />
              {listings.map((date) => <Dropdown.Item key={date} eventKey={date}>{date}</Dropdown.Item>)}
            </>
          ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ReportSetListing;
