/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import {
  Dropdown, InputGroup, Form, Spinner,
} from 'react-bootstrap';

const TAXA_ADMIN_DEFAULT = '15% (quinze por cento)';

function ContractForms(props) {
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState({});
  const [modifiedSelectedForm, setModifiedSelectedForm] = useState({});

  useEffect(() => {
    // Make the HTTP GET request to get forms responses
    fetch('https://seustudio.azurewebsites.net/api/getFormRequests')
      .then((response) => response.json())
      .then((data) => setForms(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    props.onChange(modifiedSelectedForm);
  }, [modifiedSelectedForm]);

  const handleFormSelect = (value) => {
    setSelectedForm({ ...value, taxa: TAXA_ADMIN_DEFAULT });
    setModifiedSelectedForm({ ...value, taxa: TAXA_ADMIN_DEFAULT });
  };

  const formUpdated = (value, key) => {
    setModifiedSelectedForm({ ...modifiedSelectedForm, [key]: value });
  };

  return (
    <>
      {forms && forms.length > 0 ? (
        <Dropdown style={{ ...props.style, marginLeft: '8px' }}>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            Formulários
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {forms.forEach((form) => <Dropdown.Item value={form}>{form.nome}</Dropdown.Item>)}
            <Dropdown.Menu>
              {forms.map((form) => (
                <Dropdown.Item key={form} onClick={() => handleFormSelect(form)}>
                  {form.nome}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      )
        : (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando formulários...</span>
          </Spinner>
        )}

      <div className="flex flex-wrap py-2">
        {selectedForm ? Object.keys(selectedForm).map((key) => (
          <div className="w-1/2 p-2">
            <InputGroup>
              <InputGroup.Text id={key}>{key}</InputGroup.Text>
              <Form.Control
                placeholder={key}
                aria-label={selectedForm[key]}
                aria-describedby={key}
                defaultValue={selectedForm[key]}
                onChange={(event) => formUpdated(event.target.value, key)}
                disabled={!props.enabled}
              />
            </InputGroup>
          </div>
        )) : null}
      </div>
    </>
  );
}

export default ContractForms;
