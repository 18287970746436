/* eslint-disable max-len */
const reviews = [
  {
    name: 'Janete',
    text: 'Estou tendo uma ótima experiência com Seu Studio. Eles são muito transparentes sobre seus serviços e custos, eficientes na gestão do meu imóvel e acessíveis para responder minhas dúvidas. Recomendo a empresa para qualquer pessoa que queira uma administração profissional e transparente.',
  },
  {
    name: 'Cida',
    text: 'Tenho alguns apartamentos sendo administrados pela Seu Studio e estou muito satisfeita com os serviços prestados. Não tenho nenhuma preocupação com a administração das minhas reservas, a equipe cuida de tudo para mim de forma profissional e confiável.',
  },
  {
    name: 'Djalma',
    text: 'Depois de ter problemas com outras empresas de administração de imóveis, estou feliz em dizer que finalmente encontrei a Seu Studio. Eles são altamente profissionais, confiáveis e transparentes em relação aos custos envolvidos. Estou muito satisfeito com o serviço.',
  },
];

export default reviews;
