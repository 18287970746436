/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMap } from '@fortawesome/free-regular-svg-icons';

import '../style/listing.css';

function Listing(props) {
  const [title, setTitle] = useState('');
  const [guests, setGuests] = useState('');
  const [location, setLocation] = useState('');
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    if (!props) { return; }
    console.log(props);
    setTitle(props.data.title);
    setGuests(props.data.guests);
    setLocation(props.data.location);
    setImage(props.data.image);
    setLink(props.data.link);
  }, [props]);

  return (
    <div className="listing">
      <Link to={link} target="_blank">
        <img src={image} alt="airbnb" />
      </Link>
      <h5 className="title">{title}</h5>
      {/* <h6 className="description">Test</h6> */}
      <div className="div-flex align-baseline info">
        <FontAwesomeIcon icon={faUser} />
        <p>{guests}</p>
        <FontAwesomeIcon icon={faMap} />
        <p>{location}</p>
      </div>
    </div>
  );
}

export default Listing;
