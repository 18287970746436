/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Col, Nav, NavItem, Row,
} from 'react-bootstrap';

import {
  Link, Outlet,
  useNavigation,
} from 'react-router-dom';

const MIN_SCREEN_SIZE = 768;

function Root() {
  console.log('ROOT');
  const navigation = useNavigation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < MIN_SCREEN_SIZE); // Set breakpoint as needed
    };

    // Call handleResize initially and whenever the window is resized
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Outlet />
  );
}

export default Root;
