/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import Dropzone from 'react-dropzone-uploader';
import Papa from 'papaparse';
import {
  Button, Form, InputGroup, Toast,
} from 'react-bootstrap';
import { expectedCSVColumns, validateFile } from '../utils/dataHelper';

import '../style/uploader.css';

const ERROR_MESSAGE_ARQUIVO_INCOMPATIVEL = 'Arquivo incompatível';

function Input({
  accept, onFiles, files, getFilesFromEvent,
}) {
  const text = files.length > 0 ? 'Selecionar arquivo' : 'Selecionar arquivo';

  return (
    <Form.Group controlId="formFile" as={Button} variant="success">
      <Form.Label>{text}</Form.Label>
      <Form.Control
        type="file"
        // multiple
        accept={accept}
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles);
          });
        }}
      />
    </Form.Group>
  );
}

function NoDropzoneLayout({
  previews, submitButton, input, files, dropzoneProps,
}) {
  const { ref, className, style } = dropzoneProps;
  return (
    <div ref={ref} className={className} style={style}>
      {previews}

      {input}

      {files.length > 0 && submitButton}
    </div>
  );
}

function ReportDataUpload(props) {
  const [csvErrorMessage, setCsvErrorMessage] = useState('');

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file } /* , status */) => {
    // Parse csv file
    Papa.parse(file, {
      header: false,
      download: true,
      dynamicTyping: true,
      complete: (results) => {
        if (results) {
          const { data } = results;

          // Validate CSV file
          const valid = validateFile(props.type, data[0]);

          if (valid) {
            // Reset error message
            setCsvErrorMessage('');
            // call prop function
            props.fileData(data, meta);
          } else {
            setCsvErrorMessage(ERROR_MESSAGE_ARQUIVO_INCOMPATIVEL);
            props.fileData([], '');
          }

          // const csvColumns = data[0];
          // if (csvColumns.length !== expectedCSVColumns.length) {
          //   setCsvErrorMessage(ERROR_MESSAGE_ARQUIVO_INCOMPATIVEL);
          //   props.fileData([], '');
          // } else if (!csvColumns.every((value, index) => value === expectedCSVColumns[index])) {
          //   setCsvErrorMessage(ERROR_MESSAGE_ARQUIVO_INCOMPATIVEL);
          //   props.fileData([], '');
          // } else {
          //   // Reset error message
          //   setCsvErrorMessage('');

          //   // call prop function
          //   props.fileData(data, meta);
          // }
        }
      },
    });
  };

  const getFilesFromEvent = (e) => new Promise((resolve) => {
    getDroppedOrSelectedFiles(e).then((chosenFiles) => {
      resolve(chosenFiles.map((f) => f.fileObject));
    });
  });

  return (
    <>
      <Dropzone
        onChangeStatus={handleChangeStatus}
        accept=".csv"
        LayoutComponent={NoDropzoneLayout}
        PreviewComponent={null}
        canRestart={false}
        InputComponent={Input}
        getFilesFromEvent={getFilesFromEvent}
        style={{ width: '100%' }}
      />
      {csvErrorMessage !== ''
        ? (
          <Toast bg="danger">
            <Toast.Header>
              <strong>Erro de Upload</strong>
            </Toast.Header>
            <Toast.Body>{csvErrorMessage}</Toast.Body>
          </Toast>
        )
        : null}
    </>
  );
}

export default ReportDataUpload;
