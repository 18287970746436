/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import logo from '../media/logo-beige.png';
import '../style/navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Button } from "bootstrap";

import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogout = () => {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
  };

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            {/* <Link
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              to="/"
            >
              Login
                      </Link> */}
            <img className="logo" src={logo} alt="seustudio" />
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1  bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon className="sandwich-btn" icon={faBars} />
              {/* <i className="text-white fas fa-bars"></i> */}
            </button>
          </div>
          <div
            style={{marginTop: '1rem'}}
            className={
              "lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " bg-white block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
             <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">

             <li className="flex items-center">
                <a as={Button}
                  className={(navbarOpen ? "navbar-item-menu" : "navbar-item")}
                  onClick={() => setNavbarOpen(false)}
                  // className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs"
                  href="#servicos"
                >
                  {/* <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-twitter text-lg leading-lg " /> */}
                  <span className="inline-block ml-2">Serviços</span>
                </a>
              </li>

              <li className="flex items-center">
                <a as={Button}
                  className={(navbarOpen ? "navbar-item-menu" : "navbar-item" )}
                  onClick={() => setNavbarOpen(false)}
                  // className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs"
                  href="#listings"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-facebook text-lg leading-lg " />
                  <span className="inline-block ml-2">Nossos anúncios</span>
                </a>
              </li>

              <li className="flex items-center">
                <a as={Button}
                  className={(navbarOpen ? "navbar-item-menu" : "navbar-item" )}
                  onClick={() => setNavbarOpen(false)}
                  // className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs"
                  href="#transparency"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-github text-lg leading-lg " />
                  <span className="inline-block ml-2">Como funciona</span>
                </a>
              </li>
              {isAuthenticated ? <li className="flex items-center">
                <a as={Button}
                  className={(navbarOpen ? "navbar-item-menu" : "navbar-item")}
                  onClick={() => setNavbarOpen(false)}
                  // className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs"
                  href="/portal"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-github text-lg leading-lg " />
                  <span className="inline-block ml-2">Portal</span>
                </a>
              </li> : null}
              <li className="flex items-center">
                <a as={Button}
                  className={(navbarOpen ? "navbar-item-menu" : "navbar-item" )}
                  onClick={isAuthenticated ? handleLogout : handleLogin}
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-github text-lg leading-lg " />
                  <span className="inline-block ml-2">{isAuthenticated ? "Logout" : "Login"}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
