/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import pix from '../utils/pix';
import {
  getListing, getUnit, getHeaders, convertAllToString, calculateBookingTotalValue, calculateBookingTotal, numberToBRSCurrency,
} from '../utils/dataHelper';

import '../style/Roboto-Thin-normal';
import '../style/Roboto-Regular-normal';
import '../style/Roboto-Bold-normal';

import logo from '../media/logo-white.png';

const QR_CODE_SIZE = 35;
const BORDER_X = 14;
const BORDER_Y = 20;

function ReportDataExport(props) {
  const [qrCodeDataURL, setQrCodeDataURL] = useState(null);
  const [pixCopiaECola, setPixCopiaECola] = useState('');
  const [data, setData] = useState([]);
  const [selectedYearMonth, setSelectedYearMonth] = useState('');
  const [listing, setListing] = useState('');
  const [fileName, setFileName] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [bills, setBills] = useState([]);

  const PIX_KEX = '+5511987781650';
  const PIX_KEX_STRING = '50.886.145/0001-00';
  // const PIX_DESCRIPTION = 'Administracao imobiliaria';
  const PIX_MERCHANT_NAME = 'Gustavo Moreira Vaccari';
  const PIX_MERCHANT_CITY = 'Sao Paulo';

  const generatePixQRCode = async (airbnbValue, referenceText) => {
    // Create Pix
    const pixCode = pix(
      PIX_KEX,
      referenceText,
      PIX_MERCHANT_NAME,
      PIX_MERCHANT_CITY,
      airbnbValue,
    );

    // Generate QR code image
    const dataURL = await QRCode.toDataURL(pixCode);
    setQrCodeDataURL(dataURL);
    setPixCopiaECola(pixCode);
  };

  const calculateExtraSubtotal = (currency) => {
    // Calculate extra fee subtotal
    let subtotalExtras = 0;
    if (props.extraFees && props.extraFees !== []) {
      props.extraFees.forEach((item) => {
        subtotalExtras += parseFloat(item.value);
      });
    }

    if (bills && bills !== []) {
      bills.forEach((item) => {
        subtotalExtras += parseFloat(item.value);
      });
    }

    if (currency) {
      subtotalExtras = numberToBRSCurrency(subtotalExtras);
    }

    return subtotalExtras;
  };

  const calculateTotal = (d, currency) => {
    // Calculate airbnb subtotal
    // const subtotalAirbnb = calculateSubtotalValue(d);
    const bookingTotal = calculateBookingTotalValue(d);

    // Calculate extra subtotal
    const subtotalExtras = calculateExtraSubtotal(false);
    // Calculate total
    let total = (bookingTotal - subtotalExtras);
    total = parseFloat(total).toFixed(2);

    if (currency) {
      total = numberToBRSCurrency(total);
    }

    return total;
  };

  useEffect(() => {
    const yearMonth = props.yearMonth;
    const d = props.data;
    const enabled = props.enabled;

    setIsEnabled(enabled);

    if (d && d.length === 0) return;

    // Add fixed costs
    let clean = 0;
    let adm = 0;
    props.data.forEach((item) => {
      clean += item.cleaningFee.value;
    });
    props.data.forEach((item) => {
      adm += item.adminFee.value;
    });
    setBills([...props.bills,
      { name: 'Limpeza', value: clean },
      { name: 'Administração', value: adm },
    ]);

    // Get listing
    setListing(getListing(d));
    setFileName(`${yearMonth}_${getUnit(d)}`);

    setData(d);
    setSelectedYearMonth(yearMonth);

    // Calculate total
    const total = calculateTotal(d);

    // Generate Pix QR Code
    generatePixQRCode(total, `${yearMonth}_${getUnit(d)}`);
  }, [props]);

  const handleExport = () => {
    if (data && data.length === 0) return;

    // Calculate subtotals and total
    // const airbnbSubtotal = calculateSubtotal(data, true);
    const airbnbTotal = calculateBookingTotal(data, true);
    const extraFeesSubtotal = calculateExtraSubtotal(true);
    const total = calculateTotal(data, true);

    // Convert all to string to show on report
    const stringData = convertAllToString(data);

    // Create jsPDF
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('landscape');
    const pageHeight = pdf.getPageHeight();
    const pageWidth = pdf.getPageWidth();
    pdf.setFillColor(18, 77, 84);
    pdf.rect(0, 0, pageWidth, BORDER_Y + 20, 'F');

    // Title
    pdf.setFont('Roboto-Bold');
    pdf.setFontSize(22);
    pdf.setTextColor(255, 255, 255);
    pdf.text('CONTROLE DE HOSPEDAGEM', BORDER_X, BORDER_Y);

    // Information
    pdf.setFontSize(13);
    pdf.setFont('Roboto-Thin');
    pdf.text(`Unidade(s): ${listing}`, BORDER_X, BORDER_Y + 6);
    pdf.text(`Referente à: ${selectedYearMonth}`, BORDER_X, BORDER_Y + 12);
    pdf.setTextColor(0, 0, 0);

    // LOGO
    pdf.addImage(logo, 'JPEG', pageWidth - 4 * BORDER_X, 12, 40, 12);

    // AIRBNB table
    pdf.setFont('Roboto-Thin');
    autoTable(pdf, {
      styles: {
        halign: 'right', font: 'Roboto-Regular', fontSize: 10,
      },
      headStyles: {
        fillColor: [255, 255, 255], // [104, 68, 36], // [25, 135, 84],
        // font: 'Roboto-Bold',
        textColor: [0, 0, 0],
      },
      startY: BORDER_Y + 27,
      showFoot: 'lastPage',
      head: [getHeaders()],
      body: stringData,
      foot: [[{
        content: `Subtotal: ${airbnbTotal}`,
        colSpan: stringData[0].length,
        styles: {
          halign: 'right',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          font: 'Roboto-Thin',
        },
      }]],
      didParseCell(table) {
        if (table.section === 'body') {
          // eslint-disable-next-line no-param-reassign
          table.cell.styles.textColor = '#000';
          // eslint-disable-next-line no-param-reassign
          table.cell.styles.font = 'Roboto-Thin';
          // table.cell.styles.fillColor = '#ff00ff';
        }
      },
      // tableLineColor: [255, 0, 0],
      // tableLineWidth: 0.75,
      // drawHeaderRow: (head, info) => {
      //   //---------------------------------------
      //   // Write the line at the bottom of header
      //   //---------------------------------------
      //   info.doc.line(info.cursor.x, info.cursor.y + head.height, info.cursor.x + info.table.width, info.cursor.y + head.height);
      // },
    });

    // Extra fee table
    if (props.extraFees && props.extraFees.length > 0) {
      pdf.text('Cobranças extras', BORDER_X, pdf.autoTable.previous.finalY + 5);
      // Map the data to an array of arrays
      const extraFeesColumns = [['Descrição', 'Valor']];
      const extraFeesRows = [...props.extraFees].map(({ ...item }) => {
        const value = numberToBRSCurrency(item.value);
        return [item.name, value];
      });

      autoTable(pdf, {
        styles: {
          halign: 'right', font: 'Roboto-Regular', fontSize: 10,
        },
        headStyles: {
          // fillColor: [150, 150, 150], // [104, 68, 36], // [25, 135, 84],
          fillColor: [255, 255, 255], // [104, 68, 36], // [25, 135, 84],
          // font: 'Roboto-Bold',
          textColor: [0, 0, 0],
        },
        columnStyles: {
          0: { halign: 'left' },
        },
        startY: pdf.autoTable.previous.finalY + 10, // Start the table below the previous table with a gap of 10
        showFoot: 'lastPage',
        head: extraFeesColumns,
        body: extraFeesRows,
        foot: [[{
          content: `Subtotal: ${extraFeesSubtotal}`,
          colSpan: 2,
          styles: {
            halign: 'right',
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            font: 'Roboto-Thin',
          },
        }]],
        didParseCell(table) {
          if (table.section === 'body') {
            table.cell.styles.textColor = '#000';
            table.cell.styles.font = 'Roboto-Thin';
            // table.cell.styles.fillColor = '#ff00ff';
          }
          if (table.section === 'head' && table.column.index === 0) {
            table.cell.styles.halign = 'left';
          }
        },
      });
    }

    // Custos table
    if (bills && bills.length > 0) {
      pdf.text('Custos', BORDER_X, pdf.autoTable.previous.finalY + 5);
      // Map the data to an array of arrays
      const extraFeesColumns = [['Descrição', 'Valor']];
      const extraFeesRows = [...bills].map(({ ...item }) => {
        const value = numberToBRSCurrency(item.value);
        return [item.name, value];
      });

      autoTable(pdf, {
        styles: {
          halign: 'right', font: 'Roboto-Regular', fontSize: 10,
        },
        headStyles: {
          // fillColor: [150, 150, 150], // [104, 68, 36], // [25, 135, 84],
          fillColor: [255, 255, 255], // [104, 68, 36], // [25, 135, 84],
          // font: 'Roboto-Bold',
          textColor: [0, 0, 0],
        },
        columnStyles: {
          0: { halign: 'left' },
        },
        startY: pdf.autoTable.previous.finalY + 10, // Start the table below the previous table with a gap of 10
        showFoot: 'lastPage',
        head: extraFeesColumns,
        body: extraFeesRows,
        foot: [[{
          content: `Subtotal: ${extraFeesSubtotal}`,
          colSpan: 2,
          styles: {
            halign: 'right',
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            font: 'Roboto-Thin',
          },
        }]],
        didParseCell(table) {
          if (table.section === 'body') {
            table.cell.styles.textColor = '#000';
            table.cell.styles.font = 'Roboto-Thin';
            // table.cell.styles.fillColor = '#ff00ff';
          }
          if (table.section === 'head' && table.column.index === 0) {
            table.cell.styles.halign = 'left';
          }
        },
      });
    }

    // pdf.setFont('Roboto-Thin');
    // QR Code position
    // const qrCodePositionY = pageHeight - QR_CODE_SIZE - BORDER_X;
    // const qrCodePositionY = pdf.autoTable.previous.finalY + 20;

    let qrCodePositionY = pageHeight - QR_CODE_SIZE - BORDER_X;
    // QR code does not fit
    if (pdf.autoTable.previous.finalY > 160) {
      pdf.addPage();
      qrCodePositionY = BORDER_Y;
    }

    // Add QR code image to PDF
    // pdf.setFont('Roboto-Thin');
    // pdf.text(BORDER_X + 2, qrCodePositionY - 2, 'QR Code Pix');
    // pdf.addImage(qrCodeDataURL, 'PNG', BORDER_X, qrCodePositionY, QR_CODE_SIZE, QR_CODE_SIZE);

    // Add pix copia e cola
    // eslint-disable-next-line prefer-template
    // pdf.text(60, qrCodePositionY - 2, 'Pix Copie e Cole');
    // const splitTitle = pdf.splitTextToSize(`${pixCopiaECola} `, 80);
    // pdf.text(60, qrCodePositionY + 6, splitTitle);

    // Calculate the position of the total text
    pdf.setFontSize(12);
    const totalLenght = pdf.getTextWidth(`Total: ${total}`);

    // Add the total text to the document
    pdf.setFont('Roboto-Bold');
    // pdf.text(`Total: ${total}`, pageWidth - totalLenght - BORDER_X - 3, pageHeight - BORDER_Y);
    pdf.text(`Total: ${total}`, pageWidth - totalLenght - BORDER_X - 3, pageHeight - BORDER_Y);
    // pdf.text(`Total: ${total}`, BORDER_X + 2, pageHeight - BORDER_Y / 2);
    pdf.setFontSize(12);

    pdf.setFont('Roboto-Thin');
    pdf.text(`Chave Pix: ${PIX_KEX_STRING}`, BORDER_X, pageHeight - BORDER_Y);

    // pdf.setFont('Roboto-Thin');
    // QR Code position
    qrCodePositionY = pageHeight - QR_CODE_SIZE - BORDER_X;

    // Add QR code image to PDF
    pdf.setFont('Roboto-Thin');
    // pdf.text(BORDER_X + 2, qrCodePositionY - 2, 'Pagar com Pix');
    // pdf.addImage(qrCodeDataURL, 'PNG', BORDER_X, qrCodePositionY, QR_CODE_SIZE, QR_CODE_SIZE);

    // Add pix copia e cola
    // eslint-disable-next-line prefer-template
    // pdf.text(60, qrCodePositionY - 2, 'Copia e Cola');
    // const splitTitle = pdf.splitTextToSize(`${pixCopiaECola} `, 80);
    // pdf.text(60, 170, splitTitle);

    // See preview in browser
    // pdf.output('dataurlnewwindow');

    // Save pdf
    // const fileName = `${selectedYearMonth}_${unit}`;
    pdf.save(fileName);
  };

  return (
    <Button
      className={props.className}
      variant="success"
      disabled={!isEnabled}
      onClick={handleExport}
    >
      Baixar PDF

    </Button>
  );
}

export default ReportDataExport;
