/* eslint-disable import/prefer-default-export */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-undef */
/* eslint-disable max-len */
const clausulas = [
  {
    title: 'PARÁGRAFO ÚNICO - SEGUROS',
    content: ['É recomendado que o PROPRIETÁRIO faça um seguro contra incêndios, do imóvel a ser locado, em seguradora idônea. As locações negociadas através da plataforma Airbnb são asseguradas pelo AirCover - seguradora da plataforma. O administrador poderá solicitar a cobertura do seguro a qualquer momento em caso de avarias ou danos causados durante a hospedagem.'],
  },
  {
    title: 'CLÁUSULA PRIMEIRA – DA PRESTAÇÃO DE SERVIÇO, OBRIGAÇÕES E DEVERES DA PRESTADORA',
    content: ['1.1 As partes acordam que a PRESTADORA se encarregará da administração das locações por temporada do imóvel durante a vigência deste termo de adesão prestando os seguintes serviços:'],
  },
  {
    title: 'CLÁUSULA SEGUNDA – DAS OBRIGAÇÕES e CONCORDÂNCIAS DO PROPRIETÁRIO',
    content: ['2.1 Fornecer à PRESTADORA a descrição do imóvel por meio do preenchimento do formulário deste Termo. Os ITENS ESSENCIAIS deverão ser fornecidos pelo PROPRIETÁRIO e estar disponíveis no imóvel antes da primeira locação. Os ITENS ESSENCIAIS e os OPCIONAIS SUGERIDOS serão conferidos pela PRESTADORA em todos os checkouts para garantir a integridade e a manutenção deles. Quaisquer itens excedentes não serão conferidos.',
      '2.2 Garantir que as contas de consumo (internet, gás, luz, água) estejam sempre em dia para que os serviços não cessem durante a estadia dos locatários.',
      '2.3 Assumir a reposição de itens danificados assim como realizar obras de manutenção (pintura e outras) quando comprovadamente resultantes de desgaste natural, tempo de uso, acidentes climáticos e afins.',
      '2.4 Caso o PROPRIETÁRIO administre pessoalmente anúncios em sites de divulgação de imóveis para locação por temporada e/ou mídias sociais, deverá comunicar a PRESTADORA para que ela analise a qualidade desses anúncios e, caso haja necessidade, possa solicitar ajustes e informações de disponibilidade do calendário ao PROPRIETÁRIO.',
      '2.5 Recolher todos os impostos e taxas que venham a ser aplicados sobre o imóvel, isentando assim a PRESTADORA de qualquer responsabilidade sobre estes recolhimentos ou comunicações de cobranças.',
      '2.6 Manter atualizado o status de disponibilidade do IMÓVEL junto à PRESTADORA, por meio de e-mail e/ou grupo WHATSAPP.',
      '2.7 Solicitar cancelamentos de reservas a PRESTADORA, por meio de e-mail e/ou grupo WHATSAPP, com antecedência superior a 60 dias da data do check-in.',
      '2.8 Pagar uma taxa única de R$ 150,00 para a primeira limpeza e arrumação do imóvel.',
      '2.9 A primeira compra de produtos de limpeza, que consta no ANEXO 1 deverá ser adquiridas pelo PROPRIETÁRIO e as próximas compras para reposição dos itens, serão de responsabilidade da empresa responsável pela limpeza pós checkout e descontadas no relatório de prestação de contas do PROPRIETÁRIO'],
  },
  {
    title: 'CLÁUSULA TERCEIRA – DO PAGAMENTO DA LOCAÇÃO',
    content: ['3.1 O PROPRIETÁRIO(S) receberá os pagamentos oriundos das locações e as taxas de limpeza direto em sua conta bancária. Os prazos de recebimento variam de acordo com as regras das plataformas de locação. Na plataforma AIRBNB, os valores da hospedagem e taxas de limpeza são depositados em até 48 horas após o check in do hóspede. Booking e outras plataformas poderão emitir os pagamentos no prazo máximo de 30 a 45 dias. A cada 15 dias, a PRESTADORA enviará os custos de administração e limpeza que deverão ser pagos à PRESTADORA pelo PROPRIETÁRIO em até 48 horas através de boleto bancário ou PIX.',
      '3.2 A PRESTADORA se responsabiliza por intermediar junto aos sites de divulgação todas as ocorrências relacionadas a quaisquer prejuízos financeiros relativos à locação: avarias, inadimplência, fraudes e outros. Entretanto, a PRESTADORA não se responsabiliza por qualquer restituição financeira oriunda de insucesso em negociações com os sites.',
      '3.3 As faxinas são cobradas dos locatários e repassadas à equipe de limpeza terceirizada. Quando identificada pela PRESTADORA a necessidade de uma faxina mais detalhada o PROPRIETÁRIO se responsabilizará pelo pagamento desse valor que se caracteriza um serviço extraordinário.',
      '3.4 - O PROPRIETÁRIO deverá pagar mensalmente à PRESTADORA o valor de ${taxa} pelo custo de operação/administração. Esse valor será deduzido do rendimento líquido das locações, após desconto das taxas da plataforma. Despesas como IPTU, ÁGUA, LUZ, INTERNET, CONDOMÍNIO e outras inerentes a operação do imóvel são de responsabilidade apenas do PROPRIETÁRIO.',
      '3.5 - O Proprietário deverá pagar mensalmente à PRESTADORA os valores referentes às taxas de limpeza em sua totalidade no período compreendido'],
  },
  {
    title: 'CLÁUSULA QUARTA – DA VIGÊNCIA DO TERMO DE ADESÃO',
    content: ['4.1 - O presente Termo de Adesão deverá ser aceito eletronicamente por este meio e será válido por um período de 6 (seis) meses a partir da data do aceite, sendo automaticamente renovado por prazo indeterminado a não ser que uma das partes notifique a outra por escrito, com antecedência de 30 (trinta) dias.'],
  },
  {
    title: 'CLÁUSULA QUINTA – DAS PENALIDADES',
    content: ['5.1 - Solicitação de cancelamentos de reservas em um período de 90 dias antes da data check-in incorrerá em uma multa de 15% (quinze por cento) do valor da locação a ser paga à PRESTADORA pelo PROPRIETÁRIO ',
      'A efetivação de 3 cancelamentos consecutivos, solicitados pelo PROPRIETÁRIO, incorrerá na rescisão automática deste Termo de Adesão.',
      '5.2 -  O cancelamento deste Termo de Adesão, antes do cumprimento da vigência de 6 (seis) meses, o PROPRIETÁRIO deverá à PRESTADORA:',
      '5.2.1 - Comunicar através de e-mail a decisão de rescisão contratual.',
      '5.2.2 - Pagar à PRESTADORA uma multa no valor fixo de R$ 1.200,00 (hum mil e duzentos reais) no período de 90 dias - caso ocorra CANCELAMENTO unilateral a partir da assinatura do contrato.. Para cobertura de gastos com colaboradores contratados para agregarem a operação.',
      '5.2.3 - Honrar todas as reservas confirmadas no imóvel até a data da solicitação do cancelamento do Termo de Adesão.',
      '5.3 - Havendo a decisão de vender o imóvel, o PROPRIETÁRIO deverá comunicar por escrito à PRESTADORA e respeitar as reservas e locações em curso, caso contrário, o PROPRIETÁRIO deverá assumir absoluta responsabilidade por quaisquer perdas ou danos financeiros incorridos ao LOCATÁRIO e/ou à PRESTADORA não incidindo a multa da cláusula.',
    ],
  },
  {
    title: 'CLÁUSULA ÚNICA - MULTA DE CANCELAMENTO',
    content: ['Caberá a PRESTADORA indenizar o PROPRIETÁRIO no valor fixo de R$ 1.200,00 (hum mil e duzentos reais) caso ocorra cancelamento UNILATERAL dentro do período de 90 dias a partir da assinatura do contrato.'],
  },
  {
    title: 'CLÁUSULA SEXTA – DISPOSIÇÕES FINAIS',
    content: ['6.1 - A PRESTADORA disponibiliza assessoria jurídica nos âmbitos judicial e extrajudicial para qualquer eventualidade, a serem negociados em Termo de Adesão específico.',
      '6.2 - O PROPRIETÁRIO se compromete a aceitar as atualizações referentes a este termo de adesão, sob pena de rescisão contratual, caso isso não ocorra'],
  },
  {
    title: 'CLÁUSULA SÉTIMA - DO FORO',
    content: ['Por estarem de acordo firmam este documento, ficando eleito o Foro da Comarca de São Paulo/SP para dirimir quaisquer questões oriundas deste acordo, renunciando as partes a qualquer outro, por mais privilegiado que seja.'],
  },
];

export { clausulas };
