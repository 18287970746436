/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { msalConfig } from './authConfig';

const root = ReactDOM.createRoot(document.getElementById('root'));

const pca = new PublicClientApplication(msalConfig);

function AppWithAuthentication() {
  // console.log(pca);
  return (
    <React.StrictMode>
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
}

root.render(<AppWithAuthentication />);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );
