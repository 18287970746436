// https://github.com/gab618/pix-js/blob/master/Pix.js
// https://medium.com/uxips/gerando-c%C3%B3digo-pix-no-google-spreadsheets-6303432343e8
// https://www.bcb.gov.br/content/estabilidadefinanceira/forumpireunioes/AnexoI-PadroesParaIniciacaodoPix.pdf
// https://pix.nascent.com.br/tools/pix-qr-decoder/

const ID_PAYLOAD_FORMAT_INDICATOR = '00';
const ID_MERCHANT_ACCOUNT_INFORMATION = '26';
const ID_MERCHANT_ACCOUNT_INFORMATION_GUI = '00';
const ID_MERCHANT_ACCOUNT_INFORMATION_KEY = '01';
const ID_MERCHANT_ACCOUNT_INFORMATION_DESCRIPTION = '02';
const ID_MERCHANT_CATEGORY_CODE = '52';
const ID_TRANSACTION_CURRENCY = '53';
const ID_TRANSACTION_AMOUNT = '54';
const ID_COUNTRY_CODE = '58';
const ID_MERCHANT_NAME = '59';
const ID_MERCHANT_CITY = '60';
// const ID_ADDITIONAL_DATA_FIELD_TEMPLATE = '62';
// const ID_ADDITIONAL_DATA_FIELD_TEMPLATE_TXID = '05';
const ID_CRC16 = '63';

function getValue(id, value) {
  const size = String(value.length).padStart(2, '0');
  return id + size + value;
}

function getMechantAccountInfo(pixKey, description) {
  const gui = getValue(
    ID_MERCHANT_ACCOUNT_INFORMATION_GUI,
    'br.gov.bcb.pix',
  );
  const key = getValue(
    ID_MERCHANT_ACCOUNT_INFORMATION_KEY,
    pixKey,
  );
  const descriptionValue = getValue(
    ID_MERCHANT_ACCOUNT_INFORMATION_DESCRIPTION,
    description,
  );
  return getValue(
    ID_MERCHANT_ACCOUNT_INFORMATION,
    gui + key + descriptionValue,
  );
}

// function getAdditionalDataFieldTemplate(txid) {
//     const txidValue = getValue(
//         ID_ADDITIONAL_DATA_FIELD_TEMPLATE_TXID,
//         txid
//     );
//     return getValue(ID_ADDITIONAL_DATA_FIELD_TEMPLATE, txidValue);
// }

// Cyclic redundancy check - Error-detecting code
function getCRC16(payl) {
  // ADD ADDITIONAL CHECK DATA TO PAYLOAD
  const payload = `${payl + ID_CRC16}04`;

  // DEFINITION
  const polynom = 0x1021;
  let result = 0xffff;
  const { length } = payload;

  // CHECKSUM
  // eslint-disable-next-line no-plusplus
  for (let offset = 0; offset < length; offset++) {
    // eslint-disable-next-line no-bitwise
    result ^= payload.charCodeAt(offset) << 8;
    // eslint-disable-next-line no-plusplus
    for (let bitwise = 0; bitwise < 8; bitwise++) {
      // eslint-disable-next-line no-bitwise, no-cond-assign
      if ((result <<= 1) & 0x10000) result ^= polynom;
      // eslint-disable-next-line no-bitwise
      result &= 0xffff;
    }
  }

  // Return 4 character CRC16 code
  return `${ID_CRC16}04${result.toString(16).toUpperCase()}`;
}

const pix = (pixKey, description, merchantName, merchantCity, amount) => {
  const payloadFormatIndicator = getValue(ID_PAYLOAD_FORMAT_INDICATOR, '01');
  const merchantAccountInfo = getMechantAccountInfo(pixKey, description);
  const merchantCategoryCode = getValue(ID_MERCHANT_CATEGORY_CODE, '0000');
  const transactionCurrency = getValue(ID_TRANSACTION_CURRENCY, '986');
  const transactionAmount = getValue(ID_TRANSACTION_AMOUNT, amount);
  const countryCode = getValue(ID_COUNTRY_CODE, 'BR');
  const merchName = getValue(ID_MERCHANT_NAME, merchantName);
  const merchCity = getValue(ID_MERCHANT_CITY, merchantCity);

  const payload = payloadFormatIndicator
    + merchantAccountInfo
    + merchantCategoryCode
    + transactionCurrency
    + transactionAmount
    + countryCode
    + merchName
    + merchCity;
  // getAdditionalDataFieldTemplate(txid);

  return payload + getCRC16(payload);
};

export default pix;
